import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-faq-list',
  templateUrl: './faq-list.component.html',
  styleUrls: ['./faq-list.component.css']
})
export class FaqListComponent implements OnInit {
  loadFaq: Array<any> = [];
  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    this.getList()
  }
  getList() {
    this.commonService.activateSpinner()
    let payLoad = {
      TENANT_CODE: 88968955,
      "ContentType": 100
    }
    this.commonService.postCall("LoadDisplayFAQs", payLoad).subscribe((res: any) => {
      this.loadFaq = res;
      this.commonService.deactivateSpinner();
    }, e => { this.commonService.deactivateSpinner() })
  }
  navigateToOuk() {
    // window.open('https://ouk.ac.ke/')
  }
}
