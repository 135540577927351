<header>
    <div class="topbar1 d-flex align-items-center">
        <a href="javascript:" (click)="navigateToOuk()">
            <img src="./../../../assets/img/ouk-logo-new.png" class="logo-icon" alt="logo icon">
          </a>
        <nav class="navbar navbar-expand ps-0">
            <nav aria-label="breadcrumb" class="d-none d-sm-none d-md-block">
            </nav>
            <div class="mobile-toggle-menu ms-auto"><i class='bx bx-menu'></i>
            </div>

            <div class="search-bar flex-grow-1">
                <div class="position-relative search-bar-box">
                    <input type="text" class="form-control search-control" placeholder="Type to search..."> <span
                        class="position-absolute top-50 search-show translate-middle-y"><i
                            class='bx bx-search'></i></span>
                    <span class="position-absolute top-50 search-close translate-middle-y"><i
                            class='bx bx-x'></i></span>
                </div>
            </div>
            <div class="text-center m-auto">
                <h4 class="title"> <b>The Open University of Kenya</b> </h4>
            </div>

        </nav>
    </div>
</header>
<div class="accordion" id="accordionExample1" style="margin:2%">
    <div class="accordion-item" *ngFor="let item of loadFaq; let i = index">
        <h2 class="accordion-header" [id]="'heading' + i">
          <button class="accordion-button" type="button" [attr.data-bs-toggle]="'collapse'" [attr.data-bs-target]="'#collapse' + i"
            [attr.aria-expanded]="i === 0 ? 'true' : 'false'" [attr.aria-controls]="'collapse' + i">
            <b>{{ item.HEADER }}</b>
          </button>
        </h2>
        <div [id]="'collapse' + i" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading' + i" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            {{item.Text}}
          </div>
        </div>
      </div>
      

</div>