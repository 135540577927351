<div class="mainDiv">
    <div class="cardStyle">
        <form action="" method="post" name="signupForm" id="signupForm">

            <img src="./../../../../assets/img/ouk-logo-new.png" id="signupLogo" />

            <h2 class="formTitle">
                Change Your Password
            </h2>
            <form action="" [formGroup]="myForm" (ngSubmit)="submit(myForm)">

                <div class="inputDiv">
                    <label class="inputLabel" for="password">New Password</label>
                    <input type="password" id="password" name="password" placeholder="Example:A123a123#@"
                        formControlName="password" (keyup)="checkPassword()"
                        [type]="fieldTextType ? 'text' : 'password'">
                    <span class="input-group-addon" style="position: relative;
                    left: 90%;
                    bottom: 27px; display: flex;">
                        <i class="fa" [ngClass]="{'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType}"
                            (click)="toggleFieldTextType()" style="color:black; cursor: pointer;"></i>
                    </span>
                    <span>
                        <div id="message" >
                            <h6>Password must contain the following:</h6>
                            <p id="letter" class="invalid">A <b>lowercase</b> letter</p>
                            <p id="capital" class="invalid">A <b>capital (uppercase)</b> letter</p>
                            <p id="number" class="invalid">A <b>number</b></p>
                            <p id="specialCharacter" class="invalid">A <b>Special</b>Character</p>
                            <p id="length" class="invalid">Minimum <b>8 characters</b></p>
                          </div>

                    </span>
                </div>


                <div class="inputDiv">
                    <label class="inputLabel" for="confirmPassword">Confirm Password</label>
                    <input type="password" id="confirmPassword" name="confirmPassword">
                </div>
            </form>
            <div class="buttonWrapper">
                <button type="submit" (click)="submit(myForm)" id="submitButton"
                    class="submitButton pure-button pure-button-primary">
                    <span>Submit</span>
                    <span *ngIf="isLoader" id="loader"></span>
                </button>
            </div>

        </form>
    </div>
</div>