export class dataDictionary {
    public static AcademicYear: string = 'bdbca9a7';
    public static AddressType: string = 'a2e00299';
    public static ApproveBlogNotification: string = '4ba80130';
    public static ApproveGroupNotfiecation: string = '5bacc3e4';
    public static CBT: string = '84f10760';
    public static Certification: string = '4a74376a';
    public static ChangePassword: string = 'd5040bff';
    public static ContactUs: string = '28866c50';
    public static ContentType: string = '024f7150';
    public static Country: string = 'ab0b614c';
    public static Course: string = '16d390dc';
    public static CourseYear: string = 'a99f0edc';
    public static CourseAssignment: string = 'ab1585b3';
    public static CoursePayments: string = 'b795a10d';
    public static Courseware: string = '4ced13e4';
    public static CustomUrlNotification: string = 'b2cf1690';
    public static Demonstration_Type: string = 'd61f9eca';
    public static District: string = '428f97c6';
    public static Documents: string = '84f10760';
    public static Email: string = 'd5a152b3';
    public static EmployementStatus: string = '3a4338ae';
    public static EmployementType: string = '46652623';
    public static EventType: string = 'cc3eb25f';
    public static FAQs: string = 'aad60388';
    public static ForgotPassword: string = '2fa1c39e';
    public static Gender: string = '6cb13da6';
    public static Language: string = '48acc59f';
    public static LogType: string = '8b3b221a';
    public static MaritalStatus: string = 'b1b5c0e5';
    public static Member: string = '3a7872f9';
    public static MultiAnswer: string = '39080bf1';
    public static MultiChoice: string = 'dd57541d';
    public static Online: string = '1632fb7f';
    public static OnlineMaterial: string = '1632fb7f';
    public static Organization: string = 'daa8956f';
    public static Percentage: string = '1ac8a2c6';
    public static Poll: string = 'a02a5d0d';
    public static PostAssessment: string = '86f3344d';
    public static PostAssessmentNotification: string = '2524aa00';
    public static PreAssessment: string = '0701774e';
    public static Priviliges: string = 'fb332dc3';
    public static ProficiencyLevel: string = 'c8a33817';
    public static ProjectLocation: string = '521a408c';
    public static ProjectLocationType: string = '8594a646';
    public static RegisterationNotification: string = 'e2dd28ef';
    public static Sections: string = 'a2c992da';
    public static Semester: string = 'b118864f';
    public static Sms: string = 'e71158bc';
    public static State: string = '8770eec8';
    public static Subject: string = '3791650a';
    public static SubscriptionNotification: string = '02e1d48e';
    public static Survey: string = '4d3316f3';
    public static TaskType: string = '6b3e15ca';
    public static TextAnswer: string = '797537f0';
    public static Title: string = '8f3db27e';
    public static UserType: string = '8a03f1c4';
    public static VideoConference: string = 'b918e5c8';
    public static Videos: string = '6ec3a5ba';
    public static YesNo: string = 'fbd5afa6';
    public static EducationType: string = 'd2a65d8a';
    public static EducationName: string = '02c32788';
    public static EmploymentType: string = '46652623';
  static board: any;
  static medium: any;
  static filetypes: any;


}