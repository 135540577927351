<div class="container-scroller">
  <app-header (superMaduleEvent)="changeRole($event)"></app-header>
  <div class="container-fluid page-body-wrapper" style="position:absolute; overflow-x: hidden;">
    <app-sidebar [sMaduleId]="sMaduleId"></app-sidebar>
    <div class="main-panel">
      <router-outlet></router-outlet>
      <app-footer></app-footer>
    </div>

  </div>
</div>