import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ISearchDropDown } from './SearchDropdown';

@Component({
  selector: 'app-search-dropdown',
  templateUrl: './search-dropdown.component.html',
  styleUrls: ['./search-dropdown.component.less']
})
export class SearchDropdownComponent implements OnInit, OnChanges, AfterViewInit {
  dropdownInput: string = '';
  @Input() SearchData: ISearchDropDown;
  dropdownVisible: boolean = false;
  dropdownDiv: string = `div${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  dropdown_name: string = `dropdown_name${Date.now()}-${Math.random().toString(36).substr(2, 9)}`
  filteredDropdown = [];
  selectedItem = null;
  selectedAccId: any = '0'
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  someValue: any;

  constructor(private sanitizer: DomSanitizer, private cdr: ChangeDetectorRef) {
    this.initialData();
  }

  initialData(): void {
    this.SearchData = {
      DataList: [],
      SelectedText: "",
      SelectedId: "",
      DropdownTitle: "",
      EnabledOrDisabled: false,
      PatchId: "0",
      isRequried: false,
      placeholder: ""
    }
  }
  ngAfterViewInit(): void {
    // throw new Error('Method not implemented.');
  }
  ngOnChanges(changes: SimpleChanges) {
    let [dataListChanged, patchIdChanged] = [false, false];

    if (changes['SearchData']) {
      const currentValue = changes['SearchData'].currentValue?.DataList || [];
      const previousValue = changes['SearchData'].previousValue?.DataList || [];
      if (currentValue !== previousValue) {
        this.filteredDropdown = currentValue || [];
        dataListChanged = true;
        this.dropdownInput = "";
      }
    }
    // else
      // console.log("SearchData.DataList not changed");

    if (changes['SearchData']) {
      const currentValue = changes['SearchData'].currentValue?.PatchId || 0;
      const previousValue = changes['SearchData'].previousValue?.PatchId || 0;
      if (currentValue !== previousValue) {
        this.selectedAccId = +currentValue;
        patchIdChanged = true;
      }
    }

    if (dataListChanged || patchIdChanged) {
      if (this.selectedAccId > 0 && this.SearchData.DataList?.length > 0) {
        this.updateSelectedItem();
      }
    }
  }

  updateSelectedItem(): void {
    // console.log(`selectedAccId:=${this.selectedAccId} ----> dropdown_name:=${this.dropdown_name}`);
    setTimeout(() => {
      if (this.selectedAccId > 0 && this.SearchData.DataList?.length > 0) {
        const updatedata = this.SearchData.DataList.find(e => e[this.SearchData.SelectedId] == this.selectedAccId);
        if (updatedata) {
          this.selectItem(updatedata);
          // console.log(`selectedAccId:=${this.selectedAccId} ----> dropdown_name:=${this.dropdownInput}`);
        }
      }

    }, 100);
  }

  ngOnInit(): void {
    const newValue = "";
    this.someValue = newValue;
    this.cdr.detectChanges();
  }

  AfterViewInit(): void {
    const newValue = "";
    this.someValue = newValue;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    const target = event.target as HTMLElement;

    if (!target.closest(`#${this.dropdown_name}`) && !target.closest(`#${this.dropdownDiv}`)) {
      this.dropdownVisible = false;
    }

  }

  showDropdown() {
    this.dropdownVisible = true;
  }

  filterDropDown() {
    const input = this.dropdownInput.toLowerCase();
    this.filteredDropdown = this.SearchData.DataList.filter(e => e[this.SearchData.SelectedText].toLowerCase().includes(input));
    this.dropdownVisible = this.filteredDropdown.length > 0;
    if (!input) {
      this.selectedItem = null;
      this.notifyParent();
    }
  }


  selectItem(item: any) {
    this.dropdownInput = item[this.SearchData.SelectedText];
    this.selectedItem = item;
    this.notifyParent();
    this.dropdownVisible = false;
  }

  highlightText(text: string, input: string): SafeHtml {
    const index = text.toLowerCase().indexOf(input.toLowerCase());
    if (index !== -1) {
      const highlightedText = text.substring(0, index) +
        '<span style="color:blue; font-weight:bold">' +
        text.substring(index, index + input.length) +
        '</span>' +
        text.substring(index + input.length);
      return this.sanitizer.bypassSecurityTrustHtml(highlightedText);
    }
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }

  notifyParent() {
    this.notify?.emit({ ...this.selectedItem });
    // console.table(this.selectedItem);

  }

}