<div class="form-group position-relative">
    <label for="countryInput" *ngIf="SearchData?.DropdownTitle != ''">{{SearchData?.DropdownTitle}}:</label> <span
        *ngIf="SearchData?.isRequried" class="text-danger">*</span>
    <!-- {{SearchData|json}} -->
    <!-- {{filteredDropdown|json}} -->
    <!-- {{dropdownDiv}} -->
    <input type="text" class="form-control" [id]="dropdown_name" autocomplete="off" [name]="dropdown_name"
        [(ngModel)]="dropdownInput" (focus)="showDropdown()" (input)="filterDropDown()"
        [disabled]="SearchData?.EnabledOrDisabled" [placeholder]="SearchData?.placeholder || 'Select'">

    <div [id]="dropdownDiv" class="dropdown-menu w-100" *ngIf="dropdownVisible">
        <a class="dropdown-item" *ngFor="let item of filteredDropdown"
            [innerHTML]="highlightText(item[SearchData?.SelectedText], dropdownInput)"
            (click)="selectItem(item)">{{item[SearchData?.SelectedText]}}</a>
    </div>

</div>