import { ToastrService } from 'ngx-toastr';
import { Injectable, HostListener } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserIdleTimeOutService {

  userActiveTimer;
  public isUserInactive: Subject<any> = new Subject();
  constructor(private toastr: ToastrService) {
    this.timeoutUserActivity();
    this.isUserInactive.subscribe(() => {
      const logOut = document.getElementById('logOut');
      // this.timeoutUserActivity();
      if (logOut) {
        logOut.click();
        // console.log('user has been inactive for 3hrs');
        this.toastr.warning("Session Timeout")
      }
    });
  }
  ngOnDestroy(): void {
    this.isUserInactive.unsubscribe();
  }

  timeoutUserActivity() {
    // 180 * 60 * 1000
    const timeout = 45 * 60 * 1000;
this.userActiveTimer = setTimeout(() => this.isUserInactive.next(undefined), timeout);
    // this.userActiveTimer = setTimeout(() => this.isUserInactive.next(undefined), 180 * 60 * 1000);
  }
}
