import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private menusSource = new BehaviorSubject<Array<any>>([]);
  menus$ = this.menusSource.asObservable();

  constructor() { }

  setMenus(menus: Array<any>) {
    this.menusSource.next(menus);
  }

  getMenus() {
    console.log(this.menusSource.value,'------menusSource-----');
    
    return this.menusSource.value;
  }
}
