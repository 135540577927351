<div class="container-scroller">
    <!-- <app-header></app-header> -->
    <!-- <div class="container-fluid page-body-wrapper" style="position:absolute;"> -->
    <div class="container-fluid page-body-wrapper">
        <!-- <app-sidebar></app-sidebar> -->
        <div class="main-panel">
            <div class="page-wrapper pt-4">
                <div class="page-content pt-0 pe-0 ps-0">

                    <!-- <app-main-slider></app-main-slider> -->
                    <!-- <app-about-us></app-about-us> -->
                    <!-- <app-all-categories [allCourseList]="allCourseList" [categoryList]="categoryList"
                        (categoryEvent)="categoryChanged($event)"></app-all-categories> -->
                    <!-- <app-autocomplete-demo [allCourseList]="allCourseList" (categoryEvent)="courseChanged($event)"></app-autocomplete-demo> -->
                    <app-all-courses [courseList]="courseList"></app-all-courses>
                    <!-- <app-contact-us></app-contact-us>
                    <app-trending-courses [trending_courseList]="trending_courseList"></app-trending-courses>
                    <app-test-monials></app-test-monials>
                    <app-partners></app-partners> -->
                    <!-- <app-footer-new></app-footer-new> -->
                    <app-footer></app-footer>
                </div>
            </div>
            <!-- <router-outlet></router-outlet> -->
        </div>

    </div>
</div>