import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isThisISOWeek } from 'date-fns';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-staff-idcard-historylist',
  templateUrl: './staff-idcard-historylist.component.html',
  styleUrls: ['./staff-idcard-historylist.component.css']
})
export class StaffIdcardHistorylistComponent implements OnInit {
  table: Array<any> = [];
  headingName: any = {};
  workItemId: any;
  constructor(private commonService: CommonService,private active : ActivatedRoute) {
    this.active.params.subscribe(res=>{
      this.workItemId = res.id
    })
    this.loadStaff();
    this.loadHeadings();
  }

  ngOnInit(): void {
  }

  loadHeadings() {
    this.headingName = {};
    this.commonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }
  loadStaff() {
    let payload = {
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.commonService.postCall('EmsGenerateIDCards/StaffIdCardList', payload).subscribe((res: any) => {
      this.table = res;
    })
  }
}

