<!-- <footer class="footer">
    <div class="d-sm-flex justify-content-center justify-content-sm-between">
      <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2020 <a
          href="http://www.dhanushinfotech.com/pages/index.htm" target="_blank">Dhanush Infotech</a>. All rights
        reserved.</span>

    </div>
  </footer> -->

<!-- <div class="overlay toggle-icon"> -->
<!--end overlay-->
<!--Start Back To Top Button--> <a href="javaScript:;" class="back-to-top"><i class='bx bxs-up-arrow-alt'></i></a>
<!--End Back To Top Button-->
<!-- <footer class="page-footer">
    <p class="mb-0">Copyright © 2023 <a
      href="http://www.dhanushinfotech.com/pages/index.htm" target="_blank">Samvaad Infotech</a>. All right reserved.</p>
  </footer> -->
<footer class="page-footer" id="page-footer">
  <div class="row">
    <div
      [ngClass]="{'container': true,  'col-md-3': currentMenu && currentMenu,  'col-md-1': currentMenu && currentMenu ? false : true }">
      <div class="image-container">
        <img src="../../assets/img/oukshortcutlogo.png" class="img-fluid" alt="">
      </div>
      <!-- <div  [ngClass]="{'admissions-text': true, 'ellipsis': currentMenu && currentMenu.length > 14}">{{currentMenu}}</div>   -->
      <div class="admissions-text">{{currentMenu}}</div>
    </div>
  </div>
  <div
    [ngClass]="{'cursor-d': true ,'col-md-9': currentMenu && currentMenu , 'col-md-11': currentMenu && currentMenu ? false : true }"
    class="pal-9">
    <span class="small">{{isApplicant ? 'OUK Admissions.': '© All rights reserved. Directorate of ICT, 2024.'}}</span>
  </div>
  <!-- <p class="mb-0 "> -->
  <!-- <h6>{{labelName}}</h6> -->

  <!-- <a href="javascript:void(0)"> Copyright © {{currentYear}} The Open University of kenya All Rights Reserved.</a> -->
  <!-- <a href="javascript:void(0)"> Copyright © {{currentYear}} Dhanush Infotech All Rights Reserved.</a> -->
  <!-- </p> -->
</footer>

<!-- </div> -->