import { Component, OnInit } from "@angular/core";
import { MenuService } from "../services/menu.service";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
  currentYear: number = new Date().getFullYear();
  labelName = localStorage.getItem("menuName");
  roleName: string = localStorage.getItem('RoleName');
  applicantId = localStorage.getItem('IsApplicant');
  isApplicant = this.roleName == 'Member' && this.applicantId === '1' ? true : false;
  menus: Array<any> = [];
  currentUrl: string;
  currentMenu: string;

  constructor(private menuService: MenuService, private router: Router) {}

  ngOnInit(): void {
    // console.log(this.labelName);
    const pathSegment = window.location.pathname.split("/")[2];
    this.currentMenu = (pathSegment === "programs") ? "Programs" : (pathSegment === "dashboard") ? "Dashboard" : this.currentMenu;
    
    this.menuService.menus$.subscribe((menus) => {
      this.menus = menus;
      // Call findParentObject during component initialization
      this.updateCurrentMenu(window.location.pathname);
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.urlAfterRedirects.includes("HOME")) {
          this.updateCurrentMenu(event.urlAfterRedirects);
        }
      });
  }
 
  updateCurrentMenu(url: string): void {
    this.currentUrl = url.replace("/HOME/", "");
    this.currentMenu = this.findParentObject(this.menus, this.currentUrl);
  }
  
  findParentObject(menus: any, currentPath: any): any {
    const paths = [
      'registration/editUserRegistration',
      'registration/profile',
      'registration/editRegistrationForm',
      'components/regForm',
      'components/prnlDetailsRegForm'
    ];
    if (currentPath === "dashboard") {
      return "Dashboard";
    }
    else if(currentPath === "programs"){
      return "Programs"
    }
    else if (paths.some(path => currentPath.includes(path))) {
      return "Registrations";
    }
    else if(currentPath.includes('components/payment')){
      return "Payments"
    }
    else if (currentPath === "myApplication") {
      return "My Application";
    }

    for (const menu of menus) {
      // Check if the current menu path matches the current path
      if (menu.path === currentPath) {
        return menu.Name;
      }
      if (menu.childs && menu.childs.length > 0) {
        for (const child of menu.childs) {
          if (child.path === currentPath) {
            return menu.Name;
          } else if (child.isParent && child.childs.length > 0) {
            const result = this.findParentObject(child.childs, currentPath);
            if (result) {
              return menu.Name;
            }
          }
        }
      }
    }
    return null;
  }
}
