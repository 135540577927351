import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SidebarStateService {
  private isExpandedSubject = new BehaviorSubject<boolean>(true);
  isExpanded$ = this.isExpandedSubject.asObservable();

  constructor() { }

  toggleIsExpanded() {
    const currentValue = this.isExpandedSubject.value;
    this.isExpandedSubject.next(!currentValue);
  }
}
