<div class="wrapper">
    <div class="page-wrapper pt-4">
        <div class="page-content pt-0 pe-2 ps-2">
            <div class="container-fluid">
                <div class="default_class">
                    <div class="content-wrapper p-0">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="card rounded shadow-lg">
                                        <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                            <h5 class="mb-0">{{headingName.StaffIDCardHistoryList}} </h5>
                                        </div>
                                        <div class="card-body">
                                            <div class="text-right mb-2">
                                            </div>
                                            <div *ngIf="table.length" class="table-responsive">
                                                <table id="patergraph" datatable
                                                    class=" table-striped table-bordered mt-2 pt-2 table-sm small display">
                                                    <thead class="top"
                                                        style="background-color: #624FD7; color:#ffffff;">
                                                        <tr>
                                                            <th> Name</th>
                                                            <th> Designation </th>
                                                            <th> Issued Date </th>
                                                            <th> Print Count </th>
                                                            <th> Status </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of table">
                                                            <td>{{item.name}}</td>
                                                            <td>{{item.role_name}}</td>
                                                            <td>{{item.reissue_date |date :"dd/MM/yyyy"}}</td>
                                                            <td>{{item.print_count}}</td>
                                                            <td>{{item.status == true ? "Re-Issue" : "Issue"}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="text-center" *ngIf="!table.length">
                                                No Records to display
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>