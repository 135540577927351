<div>
  <!-- Search Input -->

  <div class="d-flex justify-content-between align-items-center mb-3 mt-3">
    <!-- Search Input on the Left Side -->
    <div class="col-md-6">
      <input type="text" [(ngModel)]="searchTerm" (input)="onSearchInput()" placeholder="Search..."
        class="form-control" />
    </div>

    <!-- Add Button on the Right Side -->
    <div *ngIf="PaginationData?.isAdd">
      <button (click)="onAdd()" class="btn btn-primary">Add</button>
    </div>
  </div>



  <!-- Table -->
  <div class="table-responsive">
    <table class="table table-striped table-bordered" style="width:100%; white-space:nowrap;">
      <thead>
        <tr>
          <th *ngFor="let itemhead of PaginationData?.headers">{{ itemhead }}</th>
          <th *ngIf="PaginationData?.isEdit">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of paginatedData">
          <td *ngFor="let key of PaginationData?.bindingKeys" (click)="rowClick(item)" style="cursor: pointer;">
            <a href="javaScript:void(0)" *ngIf="PaginationData?.isClickableRow">{{ item[key.toLowerCase()] }}</a>            
            <span *ngIf="!PaginationData?.isClickableRow">{{ item[key.toLowerCase()] }}</span>
          </td>
          <td *ngIf="PaginationData?.isEdit">
            <button (click)="onEdit(item)" class="btn btn-sm btn-link">
              <i class="fas fa-edit"></i>
            </button>
          </td>
        </tr>
        <tr *ngIf="PaginationData?.DataList.length == 0">
          <td [attr.colspan]="PaginationData?.headers.length" class="text-center text-danger"><strong>No Records to
              display</strong></td>

        </tr>
        <!-- Optional Total Row -->
        <tr *ngIf="PaginationData?.showTotal && endEntry > 0">
          <td [attr.colspan]="PaginationData?.colspan" class="text-right"><strong>Total</strong></td>
          <td>{{ totalAmount | number:'1.2-2' }}</td>
        </tr>
      </tbody>
    </table>
  </div>


  <!-- Pagination Controls -->

  <div class="d-flex justify-content-between align-items-center mb-3 mt-3">
    <!-- Search Input on the Left Side -->
    <div class="col-md-4">
      <p class="mb-0">
        Showing {{ startEntry }} to {{ endEntry }} of {{ PaginationData?.DataList.length }} entries
      </p>
    </div>

    <!-- Add Button on the Right Side -->
    <div>
      <button [disabled]="currentPage === 1 || endEntry === 0" (click)="changePage(currentPage - 1)"
        class="btn btn-secondary btn-sm">Previous</button>
      &nbsp;&nbsp;
      <button disabled class="btn btn-primary btn-sm">{{ currentPage }}</button>
      &nbsp;&nbsp;
      <button [disabled]="currentPage === totalPages || endEntry === 0" (click)="changePage(currentPage + 1)"
        class="btn btn-secondary btn-sm">Next</button>
    </div>
  </div>

</div>