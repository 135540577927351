import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { environment } from './../../environments/environment'
//const url = environment.serviceUrl
@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  url = environment.serviceUrl
  constructor(private http: HttpClient) {
    if (location.hostname != "localhost")
      this.url = `${location.origin}/api/`;
  }

  getCource() {
    let uri = this.url + 'Courses/CourseDetails'
    let data = {
      UserId: localStorage.getItem('UserId'),
      TenantCode: localStorage.getItem('TenantCode'),
      Username: localStorage.getItem('Username'),
      Roleid_base64string: localStorage.getItem('RoleId')
    }
    return this.http.post(uri, data);
  }
  loadDashboard() {
    let uri = this.url + 'Dashboard/LoadDashboard';
    let data = {
      UserId: localStorage.getItem('UserId'),
      TenentCode: localStorage.getItem('TenantCode'),
      RoleId: localStorage.getItem('UserId'),
      Decrypt_Role:localStorage.getItem('RoleId')

    }
    // localStorage.getItem('RoleId')
    return this.http.post(uri, data);
  }

}


// {
//   "UserId":"12945884",
//   "TenantCode":"51964213",
//   "Username": "sheshu"
// }