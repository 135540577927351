<div class="mainDiv">
    <div class="cardStyle">
        <!-- <img src="./../../../../assets/img/ouk-logo-new.png" id="signupLogo" /> -->
        <img src="./../../../../assets/img/logo2-new.png" id="signupLogo" />

        <h2 class="formTitle">
            Vendor Registration
        </h2>
        <form action="" [formGroup]="myForm" (ngSubmit)="submit(myForm)">
            <div class="row" style="margin: 4px;">
                <div class="col-4 mb-3">
                    <label for="exampleFormControlInput1" class="form-label mb-1">Name/Organization</label>
                    <input type="text" class="form-control form-control1" id="example1" placeholder=""
                        formControlName='organizationName' disabled>
                </div>
                <div class="col-4 mb-3">
                    <label for="exampleFormControlInput1" class="form-label mb-1">Email Id</label>
                    <input type="text" class="form-control form-control1" id="example2" placeholder=""
                        formControlName='emailId' disabled>
                </div>
                <div class="col-4 mb-3">
                    <label for="exampleFormControlInput1" class="form-label mb-1">Mobile Number</label>
                    <input type="text" class="form-control form-control1" id="example3" placeholder=""
                        formControlName='mobileNo' disabled>
                </div>
                <h4> Organization Representative :</h4>
                <div class="col-4 mb-3">
                    <label for="exampleFormControlInput1" class="form-label mb-1">First Name<strong>:</strong><span
                            class="text-danger">*</span></label>
                    <input type="text" class="form-control form-control1" placeholder="" formControlName='first_name'>
                    <div *ngIf="myForm.get('first_name')?.touched">
                        <span class="text-danger" *ngIf="myForm.get('first_name')?.hasError('required')">
                            Please Enter First Name
                        </span>
                    </div>
                </div>
                <div class="col-4 mb-3">
                    <label for="exampleFormControlInput1" class="form-label mb-1">Last Name<strong>:</strong><span
                            class="text-danger">*</span></label>
                    <input type="text" class="form-control form-control1" placeholder="" formControlName='last_name'>
                    <div *ngIf="myForm.get('last_name')?.touched">
                        <span class="text-danger" *ngIf="myForm.get('last_name')?.hasError('required')">
                            Please Enter Last Name
                        </span>
                    </div>
                </div>
                <div class="col-4 mb-3">
                    <label for="exampleFormControlInput1" class="form-label mb-1">Organization
                        Address<strong>:</strong><span class="text-danger">*</span></label>
                    <input type="text" class="form-control form-control1" placeholder="" formControlName='org_address'>
                    <div *ngIf="myForm.get('org_address')?.touched">
                        <span class="text-danger" *ngIf="myForm.get('org_address')?.hasError('required')">
                            Please Enter Organization Address
                        </span>
                    </div>
                </div>
                <div class="col-4 mb-3">
                    <label for="exampleFormControlInput1" class="form-label mb-1">Ouk Vendor Code<strong>:</strong><span
                            class="text-danger">*</span></label>
                    <input type="text" class="form-control form-control1" placeholder="" formControlName='vendor_code'
                        disabled>
                    <div *ngIf="myForm.get('vendor_code')?.touched">
                        <span class="text-danger" *ngIf="myForm.get('vendor_code')?.hasError('required')">
                            Please Enter Ouk Vendor Code
                        </span>
                    </div>
                </div>
                <div class="col-4 mb-3">
                    <label for="exampleFormControlInput1" class="form-label mb-1">Organization Registration
                        Number<strong>:</strong><span class="text-danger">*</span></label>
                    <input type="text" class="form-control form-control1" placeholder="" formControlName='org_reg_no'>
                    <div *ngIf="myForm.get('org_reg_no')?.touched">
                        <span class="text-danger" *ngIf="myForm.get('org_reg_no')?.hasError('required')">
                            Please Enter Organization Registration Number
                        </span>
                    </div>
                </div>
                <div class="col-4 mb-3">
                    <label for="exampleFormControlInput1" class="form-label mb-1"> KRA Number<strong>:</strong><span
                            class="text-danger">*</span></label>
                    <input type="text" class="form-control form-control1" placeholder="" formControlName='kra_no'>
                    <div *ngIf="myForm.get('kra_no')?.touched">
                        <span class="text-danger" *ngIf="myForm.get('kra_no')?.hasError('required')">
                            Please Enter KRA Number
                        </span>
                    </div>
                </div>
                <div class="col-4 mb-3">
                    <label for="exampleFormControlInput1" class="form-label mb-1"> Upload proof of organization
                        documents<strong>:</strong><span class="text-danger">*</span></label>
                    <input type="file" class="form-control form-control1" placeholder="" formControlName='upload_proof'
                        (change)="onFileChange($event)">
                    <h6>Your uploaded file, <a (click)="viewFile()"
                            style="cursor: pointer; color: blue; text-decoration: underline;">
                            click here
                        </a> to
                        view.
                    </h6>
                    <!-- <p>{{ fileInput }}</p> -->
                    <div *ngIf="myForm.get('upload_proof')?.touched">
                        <span class="text-danger" *ngIf="myForm.get('upload_proof')?.hasError('required')">
                            Please Enter Upload proof of organization documents
                        </span>
                    </div>
                </div>
                <div class="col-md-4 mb-3">
                    <div class="form-outline">
                        <label class="form-label">Currency<strong>:</strong><span class="text-danger">*</span></label>
                        <select class="form-select" formControlName="vreg_currency_id">
                            <option value=''>Select</option>
                            <option *ngFor="let item of currencyList" [value]='item.DICTIONARYID'>
                                {{item.DICTIONARYNAME}}
                            </option>
                        </select>
                    </div>
                </div>
                <h4> BANK DETAILS </h4>
                <div class="col-4 mb-3">
                    <label for="exampleFormControlInput1" class="form-label mb-1"> Bank Name<strong>:</strong><span
                            class="text-danger">*</span> </label>
                    <input type="text" class="form-control form-control1" placeholder="" formControlName='bank_name'>
                    <div *ngIf="myForm.get('bank_name')?.touched">
                        <span class="text-danger" *ngIf="myForm.get('bank_name')?.hasError('required')">
                            Please Enter Bank Name
                        </span>
                    </div>
                </div>
                <div class="col-4 mb-3">
                    <label for="exampleFormControlInput1" class="form-label mb-1"> Account Number<strong>:</strong><span
                            class="text-danger">*</span></label>
                    <input type="text" class="form-control form-control1" placeholder="" formControlName='account_no'>
                    <div *ngIf="myForm.get('account_no')?.touched">
                        <span class="text-danger" *ngIf="myForm.get('account_no')?.hasError('required')">
                            Please Enter Account Number
                        </span>
                    </div>
                </div>
                <div class="col-4 mb-3">
                    <label for="exampleFormControlInput1" class="form-label mb-1">Branch<strong>:</strong><span
                            class="text-danger">*</span></label>
                    <input type="text" class="form-control form-control1" placeholder="" formControlName='branch'>
                    <div *ngIf="myForm.get('branch')?.touched">
                        <span class="text-danger" *ngIf="myForm.get('branch')?.hasError('required')">
                            Please Enter Branch
                        </span>
                    </div>
                </div>
            </div>
        </form>
        <div class="text-center">
            <button type="submit" (click)="submit(myForm)" class="btn btn-primary btn-sm"
                [disabled]="myForm.invalid">Submit
            </button>&nbsp;&nbsp;
            <button type="button" (click)="close()" class="btn btn-danger btn-sm">Close</button>
        </div>
    </div>
</div>