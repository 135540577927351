// captcha.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CaptchaService {

  private firstNumber: number;
  private secondNumber: number;
  private operator: string;
  private answer: number;

  constructor() {
    this.generateCaptcha();
  }

  // Generate a basic math captcha
  generateCaptcha(): void {
    this.firstNumber = Math.floor(Math.random() * 10);
    this.secondNumber = Math.floor(Math.random() * 10);
    this.operator = ['+', '-'][Math.floor(Math.random() * 2)];

    switch (this.operator) {
      case '+':
        this.answer = this.firstNumber + this.secondNumber;
        break;
      case '-':
        this.answer = this.firstNumber - this.secondNumber;
        break;
      // case '*':
      //   this.answer = this.firstNumber * this.secondNumber;
      //   break;
    }
  }

  getCaptchaQuestion(): string {
    return `${this.firstNumber} ${this.operator} ${this.secondNumber}`;
  }

  checkAnswer(userInput: number): boolean {
    return userInput === this.answer;
  }
  speakCaptcha(): void {
    let operatorInWords: string;

    // Map the operator to its verbal equivalent
    switch (this.operator) {
      case '+':
        operatorInWords = 'plus';
        break;
      case '-':
        operatorInWords = 'minus';
        break;
      default:
        operatorInWords = '';
        break;
    }

    const question = `${this.firstNumber} ${operatorInWords} ${this.secondNumber}`;
    const utterance = new SpeechSynthesisUtterance(question);

    utterance.lang = 'en-US'; // Set language
    utterance.rate = 0.9; // Speed of speech
    utterance.pitch = 1.0; // Pitch of voice
    utterance.volume = 1.0; // Volume level

    window.speechSynthesis.speak(utterance);
  }

}
