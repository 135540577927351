<body *ngIf="isLogin==false">
    <a href="javascript:" id="return-to-top"><i class="fa fa-arrow-up"></i></a>

    <!-- ----------------------------------------------------------------------------------------------------------------- -->
    <section class="mt-5 pt-5 pb-5 bg-all-course">

        <div class="container pb-5">
            <div class="row">
                <div class="col-xl-9">

                    <div class="card mb-3 border-0  bg-transparent">
                        <div class="row g-0 pt-4">
                            <div class="col-md-4">
                                <img [src]="courseDetails.IMAGE_URL" class="img-fluid rounded" alt="...">
                            </div>
                            <div class="col-md-8 ps-4">
                                <div class="card-body  bg-transparent">
                                    <h5 class="card-title">{{courseDetails.COURSE_NAME}}</h5>
                                    <h6 style="color:#808080">by Prof. {{courseDetails.FIRSTNAME}}
                                        {{courseDetails.LASTNAME}}</h6>
                                    <p class="card-text">{{courseDetails.COURSE_DESCRIPTION}} </p>
                                    <ul>
                                        <li *ngFor="let item of courseDetails.moreoptions">{{item}}</li>
                                    </ul>
                                    <div class="">
                                        <span><i class="fa-solid fa-star checked"></i></span>
                                        <span><i class="fa-solid fa-star checked"></i></span>
                                        <span><i class="fa-solid fa-star checked"></i></span>
                                        <span><i class="fa-solid fa-star checked"></i></span>
                                        <span><i class="fa-solid fa-star"></i></span>
                                        <!-- <span class="ms-2">(326)</span> -->
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 date pt-4">
                    <p class="date_time" style="color:#5E5E5E; font-size: 13px">Program:
                        <span class="me-2 ms-1 fw-bold">{{sessionDetails.COURSESHD_STARTDATE |
                            date:'dd-MMM-yyyy'}} to {{sessionDetails.COURSESHD_ENDDATE | date:'dd-MMM-yyyy'}}</span>
                    </p>

                    <select class="form-select date_select" [(ngModel)]="sessionDetails.COURSESHD_ID"
                        (change)="sessionChanged($event.target.value)" aria-label="Default select example">
                        <option value="0">Select Timings</option>
                        <option *ngFor="let session of sessionList" [value]="session.COURSESHD_ID">
                            {{session.COURSESHD_STARTTIME | date:'hh:mm a'}} - {{session.COURSESHD_ENDTIME | date:'hh:mm
                            a'}}</option>
                    </select>

                    <div class="d-block mt-3 mb-3">
                        <span class=" float-start price-offer-1"><i class="fa-solid fa-indian-rupee-sign"></i>
                            <!-- {{sessionDetails.COURSE_FEES|currency:' KSH '}} -->
                            {{courseFee |currency:' KSH '}}
                            <!-- <span class="price-offer-2">10,000</span>-->
                        </span>
                        <!--<span class="float-end">dsfcds</span> -->
                    </div>
                    <br>



                    <!-- <div class="form-check form-check-inline pt-3">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                          <label class="form-check-label" for="inlineCheckbox1">I would like to enroll Lorem ipsum dolor
                              sit amet, consectetur adipiscing elit. Sed eget magna enim fermentum </label>
                      </div> -->

                    <div class="d-grid mt-3">
                        <input type="button" class="d-none" id="btnEnrolled">
                        <button type="button" (click)="Enroll()" class="btn btn-enroll">Enroll Now
                        </button>
                    </div>

                </div>
            </div>

        </div>
    </section>
    <!-- ----------------------------------------------------------------------------------------------------------------------- -->
    <section style="margin-top: -3rem;">
        <div class="container">
            <div class="row">
                <div class="col-xl-9">
                    <div class="card shadow-lg">
                        <div class="card-body p-5 about_course">

                            <div class="accordion accordion-flush" id="accordionFlushExample">
                                <div class="accordion-item" *ngFor="let year of courseYear; let yearIndex = index">
                                    <h2 class="accordion-header" [attr.id]="'heading_one' + yearIndex">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse"
                                            [attr.data-bs-target]="'#collapse_one' + yearIndex"
                                            [attr.aria-expanded]="yearIndex === 0 ? 'true' : 'false'"
                                            [attr.aria-controls]="'collapse_one' + yearIndex"
                                            (click)="viewSemester(year.COURSESHD_COURSEYEAR)">
                                            <b> Program: {{ year.COURSE_YEAR }} </b>
                                        </button>
                                    </h2>

                                    <div [id]="'collapse_one' + yearIndex" class="accordion-collapse collapse"
                                        [attr.aria-labelledby]="'heading_one' + yearIndex"
                                        data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body">
                                            <div class="accordion accordion-flush"
                                                [id]="'accordionFlushExampleOne' + yearIndex">
                                                <div class="accordion-item"
                                                    *ngFor="let semester of semesterList; let semesterIndex = index">
                                                    <h2 class="accordion-header"
                                                        [attr.id]="'heading_Two' + yearIndex + '-' + semesterIndex">
                                                        <button class="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse"
                                                            [attr.data-bs-target]="'#collapse_Two' + yearIndex + '-' + semesterIndex"
                                                            [attr.aria-expanded]="semesterIndex === 0 ? 'true' : 'false'"
                                                            [attr.aria-controls]="'collapse_Two' + yearIndex + '-' + semesterIndex"
                                                            (click)="viewSubjects(semester.COURSESHD_SEMESTER)">
                                                            <b> {{ semester.SEMESTER_NAME }} </b>
                                                        </button>
                                                    </h2>
                                                    <div [id]="'collapse_Two' + yearIndex + '-' + semesterIndex"
                                                        class="accordion-collapse collapse"
                                                        [attr.aria-labelledby]="'heading_Two' + yearIndex + '-' + semesterIndex"
                                                        [attr.data-bs-parent]="'#accordionFlushExampleOne' + yearIndex">
                                                        <div class="accordion-body">
                                                            <div class="accordion accordion-flush"
                                                                [id]="'accordionFlushExampleTwo' + yearIndex + '-' + semesterIndex">
                                                                <div class="accordion-item"
                                                                    *ngFor="let subject of subjects; let subjectIndex = index">
                                                                    <h2 class="accordion-header"
                                                                        [attr.id]="'heading_Three' + yearIndex + '-' + semesterIndex + '-' + subjectIndex">
                                                                        <button class="accordion-button collapsed"
                                                                            type="button" data-bs-toggle="collapse"
                                                                            [attr.data-bs-target]="'#collapse_Three' + yearIndex + '-' + semesterIndex + '-' + subjectIndex"
                                                                            [attr.aria-expanded]="subjectIndex === 0 ? 'true' : 'false'"
                                                                            [attr.aria-controls]="'collapse_Three' + yearIndex + '-' + semesterIndex + '-' + subjectIndex"
                                                                            (click)="viewChapters(subject.SUBJECT_ID)">
                                                                            <b> {{ subject.SUBJECT_NAME }} </b>
                                                                        </button>
                                                                    </h2>
                                                                    <div [id]="'collapse_Three' + yearIndex + '-' + semesterIndex + '-' + subjectIndex"
                                                                        class="accordion-collapse collapse"
                                                                        [attr.aria-labelledby]="'heading_Three' + yearIndex + '-' + semesterIndex + '-' + subjectIndex"
                                                                        [attr.data-bs-parent]="'#accordionFlushExampleTwo' + yearIndex + '-' + semesterIndex">
                                                                        <div class="accordion-body">
                                                                            <ul
                                                                                *ngFor="let chapter of chapters; let chapterIndex = index">
                                                                                <b> chapter {{ chapterIndex + 1 }} : {{
                                                                                    chapter.CHAPTER_NAME }}</b>
                                                                                <!-- <li [innerHTML]="chapter.CHAPTER_DESCRIPTION"></li> -->
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- --------------------------------------------------------------------------------------------------------------------------------------- -->
</body>

<!-- --------------------------------------------------------------------------------------------------------------------------------- -->
<div *ngIf="isLogin==true" class="container-scroller">
    <app-header></app-header>
    <div class="container-fluid page-body-wrapper" style="position:absolute;">
        <app-sidebar></app-sidebar>
        <div class="main-panel">
            <div class="page-wrapper pt-4">
                <div class="page-content pt-0 pe-0 ps-0">

                    <section class="mt-2 pt-3 pb-5 bg-all-course">

                        <div class="container pb-5">
                            <div class="row">
                                <div class="col-xl-9">

                                    <div class="card mb-3 border-0  bg-transparent">
                                        <div class="row g-0">
                                            <div class="col-md-4">
                                                <img [src]="courseDetails.IMAGE_URL" class="img-fluid rounded"
                                                    alt="...">
                                            </div>
                                            <div class="col-md-8 ps-4">
                                                <div class="card-body  bg-transparent">
                                                    <h5 class="card-title">{{courseDetails.COURSE_NAME}}</h5>
                                                    <h6 style="color:#808080">by Prof. {{courseDetails.FIRSTNAME}}
                                                        {{courseDetails.LASTNAME}}</h6>
                                                    <p class="card-text">{{courseDetails.COURSE_DESCRIPTION}} </p>
                                                    <ul>
                                                        <li *ngFor="let item of courseDetails.moreoptions">{{item}}</li>
                                                    </ul>
                                                    <div class="">
                                                        <span><i class="fa-solid fa-star checked"></i></span>
                                                        <span><i class="fa-solid fa-star checked"></i></span>
                                                        <span><i class="fa-solid fa-star checked"></i></span>
                                                        <span><i class="fa-solid fa-star checked"></i></span>
                                                        <span><i class="fa-solid fa-star"></i></span>
                                                        <!-- <span class="ms-2">(326)</span> -->
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 date">
                                    <p class="date_time" style="color:#5E5E5E; font-size: 13px">Program Date:
                                        <span class="me-2 ms-1 fw-bold">
                                            {{sessionDetails.COURSESHD_STARTDATE |date:'dd-MMM-yyyy'}} -
                                            {{sessionDetails.COURSESHD_ENDDATE |date:'dd-MMM-yyyy'}}
                                        </span>
                                    </p>

                                    <select class="form-select date_select" [(ngModel)]="sessionDetails.COURSESHD_ID"
                                        (change)="sessionChanged($event.target.value)"
                                        aria-label="Default select example">
                                        <option value="0">Select Timings</option>
                                        <option *ngFor="let session of sessionList" [value]="session.COURSESHD_ID">
                                            {{session.COURSESHD_STARTTIME | date:'hh:mm a'}} -
                                            {{session.COURSESHD_ENDTIME | date:'hh:mm a'}}</option>
                                    </select>

                                    <div class="d-block mt-3 mb-3">
                                        <span class=" float-start price-offer-1"><i
                                                class="fa-solid fa-indian-rupee-sign"></i>
                                            <!-- {{sessionDetails.COURSE_FEES|currency:' KSH '}} -->
                                            {{courseFee|currency:' KSH '}}
                                            <!-- <span
                                                  class="price-offer-2">10,000</span> -->
                                        </span>
                                        <!-- <span class="float-end">dsfcds</span> -->
                                    </div>



                                    <!-- <div class="form-check form-check-inline pt-3">
                                          <input class="form-check-input" type="checkbox" id="inlineCheckbox1"
                                              value="option1">
                                          <label class="form-check-label" for="inlineCheckbox1">I would like to enroll
                                              Lorem ipsum dolor
                                              sit amet, consectetur adipiscing elit. Sed eget magna enim fermentum
                                          </label>
                                      </div> -->
                                    <br>

                                    <div class="d-grid mt-3">
                                        <!-- {{!isAlreadyCourseTaken}} -->
                                        <button
                                            *ngIf="sessionDetails.COURSESHD_ID > 0 && ( isCourseExpired && (!isAlreadyCourseTaken))"
                                            type="button" (click)="approveStatusCheck()" class="btn btn-enroll">Apply
                                            Now</button>
                                        <!-- data-bs-target="#myModal" data-bs-toggle="modal" -->
                                    </div>

                                </div>
                            </div>


                        </div>


                    </section>

                    <section style="margin-top: -3rem;">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-9">
                                    <div class="card shadow-lg">
                                        <div class="card-body p-5 about_course">

                                            <div class="accordion accordion-flush" id="accordionFlushExample">
                                                <div class="accordion-item"
                                                    *ngFor="let year of courseYear; let yearIndex = index">
                                                    <h2 class="accordion-header" [attr.id]="'heading_one' + yearIndex">
                                                        <button class="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse"
                                                            [attr.data-bs-target]="'#collapse_one' + yearIndex"
                                                            [attr.aria-expanded]="yearIndex === 0 ? 'true' : 'false'"
                                                            [attr.aria-controls]="'collapse_one' + yearIndex"
                                                            (click)="viewSemester(year.COURSESHD_COURSEYEAR)">
                                                            <b> Program: {{ year.COURSE_YEAR }} </b>
                                                        </button>
                                                    </h2>

                                                    <div [id]="'collapse_one' + yearIndex"
                                                        class="accordion-collapse collapse"
                                                        [attr.aria-labelledby]="'heading_one' + yearIndex"
                                                        data-bs-parent="#accordionFlushExample">
                                                        <div class="accordion-body">
                                                            <div class="accordion accordion-flush"
                                                                [id]="'accordionFlushExampleOne' + yearIndex">
                                                                <div class="accordion-item"
                                                                    *ngFor="let semester of semesterList; let semesterIndex = index">
                                                                    <h2 class="accordion-header"
                                                                        [attr.id]="'heading_Two' + yearIndex + '-' + semesterIndex">
                                                                        <button class="accordion-button collapsed"
                                                                            type="button" data-bs-toggle="collapse"
                                                                            [attr.data-bs-target]="'#collapse_Two' + yearIndex + '-' + semesterIndex"
                                                                            [attr.aria-expanded]="semesterIndex === 0 ? 'true' : 'false'"
                                                                            [attr.aria-controls]="'collapse_Two' + yearIndex + '-' + semesterIndex"
                                                                            (click)="viewSubjects(semester.COURSESHD_SEMESTER)">
                                                                            <b> {{ semester.SEMESTER_NAME }} </b>
                                                                        </button>
                                                                    </h2>
                                                                    <div [id]="'collapse_Two' + yearIndex + '-' + semesterIndex"
                                                                        class="accordion-collapse collapse"
                                                                        [attr.aria-labelledby]="'heading_Two' + yearIndex + '-' + semesterIndex"
                                                                        [attr.data-bs-parent]="'#accordionFlushExampleOne' + yearIndex">
                                                                        <div class="accordion-body">
                                                                            <div class="accordion accordion-flush"
                                                                                [id]="'accordionFlushExampleTwo' + yearIndex + '-' + semesterIndex">
                                                                                <div class="accordion-item"
                                                                                    *ngFor="let subject of subjects; let subjectIndex = index">
                                                                                    <h2 class="accordion-header"
                                                                                        [attr.id]="'heading_Three' + yearIndex + '-' + semesterIndex + '-' + subjectIndex">
                                                                                        <button
                                                                                            class="accordion-button collapsed"
                                                                                            type="button"
                                                                                            data-bs-toggle="collapse"
                                                                                            [attr.data-bs-target]="'#collapse_Three' + yearIndex + '-' + semesterIndex + '-' + subjectIndex"
                                                                                            [attr.aria-expanded]="subjectIndex === 0 ? 'true' : 'false'"
                                                                                            [attr.aria-controls]="'collapse_Three' + yearIndex + '-' + semesterIndex + '-' + subjectIndex"
                                                                                            (click)="viewChapters(subject.SUBJECT_ID)">
                                                                                            <b> {{ subject.SUBJECT_NAME
                                                                                                }} </b>
                                                                                        </button>
                                                                                    </h2>
                                                                                    <div [id]="'collapse_Three' + yearIndex + '-' + semesterIndex + '-' + subjectIndex"
                                                                                        class="accordion-collapse collapse"
                                                                                        [attr.aria-labelledby]="'heading_Three' + yearIndex + '-' + semesterIndex + '-' + subjectIndex"
                                                                                        [attr.data-bs-parent]="'#accordionFlushExampleTwo' + yearIndex + '-' + semesterIndex">
                                                                                        <div class="accordion-body">
                                                                                            <ul
                                                                                                *ngFor="let chapter of chapters; let chapterIndex = index">
                                                                                                <b> chapter {{
                                                                                                    chapterIndex + 1 }}
                                                                                                    : {{
                                                                                                    chapter.CHAPTER_NAME
                                                                                                    }}</b>
                                                                                                <!-- <li [innerHTML]="chapter.CHAPTER_DESCRIPTION"></li> -->
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <app-footer></app-footer>
                </div>
            </div>
            <!-- <router-outlet></router-outlet> -->
        </div>

    </div>
</div>







<!-- <body class="bg-white"> -->
<div class="wrapper">
    <div class="page-wrapper pt-4">
        <div class="page-content pt-0 pe-2 ps-2">
            <div class="container-fluid  px-4 ">

                <div class="card shadow-sm border-0">
                    <div class="card-header bg-white text-center border-0"><img
                            src="../../../assets/img/ouk-logo-new.png" alt="" style="width: 150px; height:auto;">
                        <div class="card-body">
                            <form action="" [formGroup]="signUpForm" (ngOnSubmit)="save(signUpForm,'')">
                                <div class="row">
                                    <div class="col-xl-12">
                                        <ul class="nav nav-tabs" id="myTab" role="tablist" style="cursor: pointer;">
                                            <li class="nav-item" role="presentation">
                                                <a data-bs-target="#home-tab" class="nav-link active" id="home"
                                                    data-bs-toggle="tab" role="tab" aria-controls="home-tab"
                                                    aria-selected="true">Personal Details</a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a data-bs-target="#Biological-info-tab" id="Biological-info"
                                                    class="nav-link" data-bs-toggle="tab" role="tab"
                                                    aria-controls="Biological-info-tab" aria-selected="false">Biological
                                                    Information</a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a data-bs-target="#Next-of-Kin-tab" id="Next-of-Kin" class="nav-link"
                                                    data-bs-toggle="tab" role="tab" aria-controls="Next-of-Kin-tab"
                                                    aria-selected="false">Next of Kin</a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a data-bs-target="#Education-Bg-tab" id="Education-Bg" class="nav-link"
                                                    data-bs-toggle="tab" role="tab" aria-controls="Education-Bg-tab"
                                                    aria-selected="false">Education Background</a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a data-bs-target="#doc-upload-tab" id="doc-upload" class="nav-link"
                                                    data-bs-toggle="tab" role="tab" aria-controls="doc-upload-tab"
                                                    aria-selected="false">Documents upload</a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a data-bs-target="#application-info-tab" id="application-info"
                                                    class="nav-link" data-bs-toggle="tab" role="tab"
                                                    aria-controls="application-info-tab"
                                                    aria-selected="false">Application Information</a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a data-bs-target="#Working-Exp-tab" id="Working-Exp" class="nav-link"
                                                    data-bs-toggle="tab" role="tab" aria-controls="Working-Exp-tab"
                                                    aria-selected="false">Work Experience</a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a data-bs-target="#Recommendations-tab" id="Recommendations"
                                                    class="nav-link" data-bs-toggle="tab" role="tab"
                                                    aria-controls="Recommendations-tab"
                                                    aria-selected="false">Referees</a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a data-bs-target="#Review-tab" id="Review" class="nav-link"
                                                    data-bs-toggle="tab" role="tab" aria-controls="Review-tab"
                                                    aria-selected="false">Review and submit</a>
                                            </li>
                                        </ul>
                                        <div class="tab-content bg-white" id="myTabContent">
                                            <div class="tab-pane fade show active" role="tabpanel"
                                                aria-labelledby="home" id="home-tab">
                                                <div class="row">
                                                    <div class="col-md-8 m-auto">
                                                        <div class="card border-0 card-shadow h-100">
                                                            <div class="card-body p-4">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div
                                                                            class="card_title mb-3 px-2 py-1 text-white">
                                                                            <h6 class="mb-0">Name</h6>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">First name <span
                                                                                    class="text-danger">*</span></div>
                                                                            <div class="col-md-6"><input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    formControlName="FIRSTNAME"
                                                                                    onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)">
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Middle name (optional)
                                                                            </div>
                                                                            <div class="col-md-6"><input type="text"
                                                                                    formControlName="MIDDLENAME"
                                                                                    onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                                    class="form-control form-control-sm">
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Last name <span
                                                                                    class="text-danger">*</span></div>
                                                                            <div class="col-md-6"><input type="text"
                                                                                    formControlName="LASTNAME"
                                                                                    onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                                    class="form-control form-control-sm">
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            class="card_title mt-2 mb-3 px-2 py-1 text-white">
                                                                            <h6 class="mb-0">Address</h6>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Country of Residence
                                                                                <span class="text-danger">*</span>
                                                                            </div>
                                                                            <div class="col-md-6"> <select
                                                                                    class="form-control form-control-sm"
                                                                                    formControlName="COUNTRY_ID">
                                                                                    <option value="">Select</option>
                                                                                    <option *ngFor='let item of country'
                                                                                        [value]='item.DICTIONARYID'>
                                                                                        {{item.DICTIONARYNAME}}</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Postal Address</div>
                                                                            <div class="col-md-6"><input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    placeholder="P.O.Box"
                                                                                    formControlName="POSTAL_ADDRESS">
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Physical Address</div>
                                                                            <div class="col-md-6"><input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    formControlName="PHYSICAL_ADDRESS">
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Postal Code</div>
                                                                            <div class="col-md-6"><input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    formControlName="POSTAL_CODE">
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">City/Town</div>
                                                                            <div class="col-md-6"><input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    formControlName="NEAREST_TOWN"
                                                                                    onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)">
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            class="card_title mb-3 px-2 py-1 text-white">
                                                                            <h6 class="mb-0">Email</h6>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Current Email <span
                                                                                    class="text-danger">*</span></div>
                                                                            <div class="col-md-6"><input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    formControlName="EMAILID_OF_EMERGENCY"
                                                                                    (blur)="validateEmail($event, 'Email')"
                                                                                    [(ngModel)]="Emailid"
                                                                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            class="card_title mb-3 px-2 py-1 text-white">
                                                                            <h6 class="mb-0">Telephone Numbers</h6>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Mobile Number 1 <span
                                                                                    class="text-danger">*</span></div>
                                                                            <div class="col-md-6"><input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    formControlName="MOBILENO"
                                                                                    (keypress)="onKeyPress($event)"
                                                                                    maxlength="12">
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Mobile Number 2</div>
                                                                            <div class="col-md-6"><input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    formControlName="MOBILENO2"
                                                                                    (keypress)="onKeyPress($event)"
                                                                                    maxlength="12">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 text-end">
                                                                        <button type="button"
                                                                            class="btn btn-sm admin-btn btn-sm px-4 rounded-0">Next</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="card-footer bg-white mt-3">
                                                                <div style="width: 35%" class="mb-2"><img
                                                                        src="../../../assets/img/OUK-Admissions-logo.png"
                                                                        class="img-fluid" alt="">
                                                                </div>
                                                                <p class="small">OUK is a public university that was
                                                                    established and
                                                                    Chartered in 2023. We aim
                                                                    to provide
                                                                    inclusive and flexible high quality higher education
                                                                    opportunities
                                                                    suited for the global
                                                                    citizens in the
                                                                    21st century. At OUK admissions, we recruit and
                                                                    enroll diverse class
                                                                    of
                                                                    students.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="Biological-info-tab" role="tabpanel"
                                                aria-labelledby="Biological-info">
                                                <div class="card border-0 card-shadow h-100">
                                                    <div class="card-body assesment pt-0 style-1 ">
                                                        <div class="row">
                                                            <div class="col-md-8 m-auto">
                                                                <div class="card border-0 card-shadow h-100">
                                                                    <div class="card-body p-4">
                                                                        <div
                                                                            class="card_title mb-3 px-2 py-1 text-white">
                                                                            <h6 class="mb-0">Biographical Information
                                                                            </h6>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Gender <span
                                                                                    class="text-danger">*</span>
                                                                            </div>
                                                                            <div class="col-md-6"> <select
                                                                                    formControlName="GENDER"
                                                                                    class="form-control form-control-sm">
                                                                                    <option value="">Select</option>
                                                                                    <option *ngFor='let item of gender'
                                                                                        [value]='item.DICTIONARYID'>
                                                                                        {{item.DICTIONARYNAME}}</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Date of Birth <span
                                                                                    class="text-danger">*</span></div>
                                                                            <div class="col-md-6"><input type="date"
                                                                                    formControlName="DOB"
                                                                                    (change)="agevalidate($event)"
                                                                                    (keydown)="disableDate()"
                                                                                    [max]="getToday()"
                                                                                    [(ngModel)]="dateofbirth"
                                                                                    class="form-control form-control-sm">
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Marital Status</div>
                                                                            <div class="col-md-6"> <select
                                                                                    formControlName="MARTIALSTATUS"
                                                                                    class="form-control form-control-sm">
                                                                                    <option value="">Select</option>
                                                                                    <option
                                                                                        *ngFor='let item of MartialStatus'
                                                                                        [value]='item.DICTIONARYID'>
                                                                                        {{item.DICTIONARYNAME}}</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Birth Country <span
                                                                                    class="text-danger">*</span></div>
                                                                            <div class="col-md-6"> <select
                                                                                    class="form-control form-control-sm"
                                                                                    formControlName="BIRTH_COUNTRY_ID">
                                                                                    <option value="">Select</option>
                                                                                    <option *ngFor='let item of country'
                                                                                        [value]='item.DICTIONARYID'>
                                                                                        {{item.DICTIONARYNAME}}</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Passport Size
                                                                                Photograph <small>(only
                                                                                    allow .jpeg or .png)</small></div>
                                                                            <div class="col-md-6">
                                                                                <div class="input-group mb-3">
                                                                                    <input type="file"
                                                                                        formControlName="UPLOAD_4"
                                                                                        (change)="changeFile($event,4)"
                                                                                        class="form-control form-control-sm">
                                                                                    <p class="text-danger float-start">
                                                                                        Max 30Mb (upload only
                                                                                        PDF,JPG,PNG,JPEG)</p><br>
                                                                                    <p *ngIf="upload_2 != 0">
                                                                                        {{upload_2}}</p>
                                                                                    <!-- <span class="input-group-text"
                                                                                        id="basic-addon2" >Replace</span> -->
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="card_title mt-2 mb-3 px-2 py-1 text-white">
                                                                                <h6 class="mb-0">Citizenship Information
                                                                                </h6>
                                                                            </div>
                                                                            <div class="row mb-2">
                                                                                <div class="col-md-4">Nationality <span
                                                                                        class="text-danger">*</span>
                                                                                </div>
                                                                                <div class="col-md-6"> <input
                                                                                        type="text"
                                                                                        formControlName="NATIONALITY"
                                                                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                                        class="form-control form-control-sm">
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-2">
                                                                                <div class="col-md-4">ID/Passport No
                                                                                    <span class="text-danger">*</span>
                                                                                </div>
                                                                                <div class="col-md-6"> <input
                                                                                        type="text"
                                                                                        formControlName="ID_PASSPORT"
                                                                                        class="form-control form-control-sm">
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-12 mt-3">
                                                                                <button type="button"
                                                                                    class="float-start btn btn-sm admin-btn btn-sm px-4 rounded-0">Previous</button>
                                                                                <button type="button"
                                                                                    class="float-end btn btn-sm admin-btn btn-sm px-4 rounded-0">Next</button>
                                                                            </div>
                                                                        </div>
                                                                        <div class="card-footer bg-white mt-3">
                                                                            <div style="width: 35%" class="mb-2"><img
                                                                                    src="../../../assets/img/OUK-Admissions-logo.png"
                                                                                    class="img-fluid" alt=""></div>
                                                                            <p class="small">OUK is a public university
                                                                                that was
                                                                                established
                                                                                and Chartered in 2023. We
                                                                                aim
                                                                                to provide
                                                                                inclusive and flexible high quality
                                                                                higher education
                                                                                opportunities suited for the global
                                                                                citizens in the
                                                                                21st century. At OUK admissions, we
                                                                                recruit and enroll
                                                                                diverse class of students.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="Next-of-Kin-tab" role="tabpanel"
                                                aria-labelledby="Next-of-Kin">
                                                <div class="card border-0 card-shadow h-100">
                                                    <div class="card-body assesment pt-0 style-1 ">
                                                        <div class="row">
                                                            <div class="col-md-8 m-auto">
                                                                <div class="card border-0 card-shadow h-100">
                                                                    <div class="card-body p-4">
                                                                        <div
                                                                            class="card_title mb-3 px-2 py-1 text-white">
                                                                            <h6 class="mb-0">Next of kin and emergency
                                                                                contacts</h6>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Full Name <span
                                                                                    class="text-danger">*</span></div>
                                                                            <div class="col-md-6"> <input type="text"
                                                                                    formControlName="NAME_OF_EMERGENCY"
                                                                                    onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                                    class="form-control form-control-sm">
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Relationship <span
                                                                                    class="text-danger">*</span></div>
                                                                            <div class="col-md-6"> <input type="text"
                                                                                    formControlName="RELATIONSHIPNAME_OF_EMERGENCY"
                                                                                    onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                                    class="form-control form-control-sm">
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Telephone <span
                                                                                    class="text-danger">*</span></div>
                                                                            <div class="col-md-6"> <input type="text"
                                                                                    (keypress)="onKeyPress($event)"
                                                                                    formControlName="MOBILE_NO_EMERGENCY"
                                                                                    maxlength="12"
                                                                                    class="form-control form-control-sm">
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Email Address</div>
                                                                            <div class="col-md-6"><input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    formControlName="ADDRESS_OF_EMERGENCY">
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Postal Address</div>
                                                                            <div class="col-md-6"> <input type="text"
                                                                                    placeholder="P.O.Box"
                                                                                    formControlName="POSTAL_ADDRESS"
                                                                                    class="form-control form-control-sm">
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Postal Code</div>
                                                                            <div class="col-md-6"><input type="text"
                                                                                    class="form-control form-control-sm"
                                                                                    formControlName="POSTALCODE_OF_EMERGENCY">
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">City/Town</div>
                                                                            <div class="col-md-6"> <input type="text"
                                                                                    formControlName="NEAREST_TOWN"
                                                                                    onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                                    class="form-control form-control-sm">
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Country <span
                                                                                    class="text-danger">*</span>
                                                                            </div>
                                                                            <div class="col-md-6"> <select
                                                                                    class="form-control form-control-sm"
                                                                                    formControlName="COUNTRY_ID">
                                                                                    <option value="">Select</option>
                                                                                    <option *ngFor='let item of country'
                                                                                        [value]='item.DICTIONARYID'>
                                                                                        {{item.DICTIONARYNAME}}</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-12 mt-3">
                                                                            <button type="button"
                                                                                class="float-start btn btn-sm admin-btn btn-sm px-4 rounded-0">Previous</button>
                                                                            <button type="button"
                                                                                class="float-end btn btn-sm admin-btn btn-sm px-4 rounded-0">Next</button>
                                                                        </div>
                                                                    </div>
                                                                    <div class="card-footer bg-white mt-3">
                                                                        <div style="width: 35%" class="mb-2"><img
                                                                                src="../../../assets/img/OUK-Admissions-logo.png"
                                                                                class="img-fluid" alt=""></div>
                                                                        <p class="small">OUK is a public university that
                                                                            was established
                                                                            and
                                                                            Chartered in 2023. We
                                                                            aim
                                                                            to provide
                                                                            inclusive and flexible high quality higher
                                                                            education
                                                                            opportunities suited for the global
                                                                            citizens in the
                                                                            21st century. At OUK admissions, we recruit
                                                                            and enroll
                                                                            diverse
                                                                            class of students.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="Education-Bg-tab" role="tabpanel"
                                                aria-labelledby="Education-Bg">
                                                <div class="card border-0 card-shadow h-100">
                                                    <div class="card-body assesment pt-0 style-1 ">
                                                        <div class="row">
                                                            <div class="col-md-8 m-auto">
                                                                <div class="card border-0 card-shadow h-100">
                                                                    <div class="card-body p-4">
                                                                        <div
                                                                            class="card_title mb-3 px-2 py-1 text-white">
                                                                            <h6 class="mb-0">Education Background</h6>
                                                                        </div>
                                                                        <div
                                                                            *ngFor="let row of educationData;let i = index">
                                                                            <div class="row mb-2">
                                                                                <div class="col-md-4">Institution Name
                                                                                </div>
                                                                                <div class="col-md-6"> <input
                                                                                        type="text"
                                                                                        [(ngModel)]="row.INSTITUTION_NAME"
                                                                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                                        [ngModelOptions]="{ standalone: true }"
                                                                                        class="form-control form-control-sm">
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-2">
                                                                                <div class="col-md-4">Country</div>
                                                                                <div class="col-md-6"> <select
                                                                                        class="form-control form-control-sm"
                                                                                        [(ngModel)]="row.EDUCATION_COUNTRY_ID"
                                                                                        [ngModelOptions]="{ standalone: true }">
                                                                                        <option value="">Select</option>
                                                                                        <option
                                                                                            *ngFor='let item of country'
                                                                                            [value]='item.DICTIONARYID'>
                                                                                            {{item.DICTIONARYNAME}}
                                                                                        </option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-2">
                                                                                <div class="col-md-4">Level of Study
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <input type="text"
                                                                                        [(ngModel)]="row.LEVEL_OF_STUDY"
                                                                                        [ngModelOptions]="{ standalone: true }"
                                                                                        class="form-control form-control-sm"><span
                                                                                        class="text-blue"><small>e.g.
                                                                                            Primary School, High
                                                                                            School, Certificate,
                                                                                            Diploma,
                                                                                            Degree,
                                                                                            Masters</small></span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-2">
                                                                                <div class="col-md-4">Start Date</div>
                                                                                <div class="col-md-6"> <input
                                                                                        type="date"
                                                                                        [(ngModel)]="row.FROM_PERIOD"
                                                                                        [ngModelOptions]="{ standalone: true }"
                                                                                        class="form-control form-control-sm"
                                                                                        id="fromDate"
                                                                                        (change)="validateDates(row)">
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-2">
                                                                                <div class="col-md-4">End Date</div>
                                                                                <div class="col-md-6"> <input
                                                                                        type="date"
                                                                                        [(ngModel)]="row.TO_PERIOD"
                                                                                        [ngModelOptions]="{ standalone: true }"
                                                                                        class="form-control form-control-sm"
                                                                                        id="toDate"
                                                                                        (change)="validateDates(row)">
                                                                                    <div *ngIf="isToDateInvalid(row)"
                                                                                        class="text-danger">
                                                                                        To date should not be greater
                                                                                        than From date.
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-2">
                                                                                <div class="col-md-4">Examination Number
                                                                                    e.g. KCSE index
                                                                                    number
                                                                                </div>
                                                                                <div class="col-md-6"> <input
                                                                                        type="text"
                                                                                        [(ngModel)]="row.EXAM_REG_NO"
                                                                                        [ngModelOptions]="{ standalone: true }"
                                                                                        class="form-control form-control-sm">
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-2">
                                                                                <div class="col-md-4">Qualifications
                                                                                    Attained</div>
                                                                                <div class="col-md-6"> <input
                                                                                        type="text"
                                                                                        [(ngModel)]="row.QUALIFICATION"
                                                                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                                        [ngModelOptions]="{ standalone: true }"
                                                                                        class="form-control form-control-sm">
                                                                                </div>
                                                                                <div
                                                                                    class="col-xl-3 col-md-6 col-12 mb-3 pt-4">
                                                                                    <button
                                                                                        class="btn btn-danger btn-sm "
                                                                                        (click)="remove(i)">Remove</button>
                                                                                </div>

                                                                            </div>



                                                                            <!-- <div class="row">
                                                                            <div class="col-12 text-center">
                                                                                  
                                                                                <button type="button"
                                                                                    class="btn btn-sm admin-btn btn-sm px-4 rounded-0" (click)="add()">Add</button>
                                                                            </div>
                                                                        </div> -->
                                                                        </div>

                                                                        <div class="col-12 mt-3">
                                                                            <button type="button"
                                                                                class="btn btn-sm admin-btn btn-sm px-4 rounded-0"
                                                                                (click)="add()">Add</button>
                                                                            <button type="button"
                                                                                class="float-start btn btn-sm admin-btn btn-sm px-4 rounded-0">Previous</button>
                                                                            <button type="button"
                                                                                class="float-end btn btn-sm admin-btn btn-sm px-4 rounded-0">Next</button>
                                                                        </div>
                                                                    </div>
                                                                    <div class="card-footer bg-white mt-3">
                                                                        <div style="width: 35%" class="mb-2"><img
                                                                                src="../../../assets/img/OUK-Admissions-logo.png"
                                                                                class="img-fluid" alt=""></div>
                                                                        <p class="small">OUK is a public university that
                                                                            was established
                                                                            and
                                                                            Chartered in 2023. We
                                                                            aim
                                                                            to provide
                                                                            inclusive and flexible high quality higher
                                                                            education
                                                                            opportunities suited for the global
                                                                            citizens in the
                                                                            21st century. At OUK admissions, we recruit
                                                                            and enroll
                                                                            diverse
                                                                            class of students.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="doc-upload-tab" role="tabpanel"
                                                aria-labelledby="doc-upload">
                                                <div class="card border-0 card-shadow h-100">
                                                    <div class="card-body assesment pt-0 style-1 ">
                                                        <div class="row">
                                                            <div class="col-md-8 m-auto">
                                                                <div class="card border-0 card-shadow h-100">
                                                                    <div class="card-body p-4">
                                                                        <div
                                                                            class="card_title mb-3 px-2 py-1 text-white">
                                                                            <h6 class="mb-0">Education supporting
                                                                                documents upload</h6>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-6">Upload Previous
                                                                                Supporting
                                                                                Certificates
                                                                                <span class="text-danger">*</span>
                                                                            </div>
                                                                            <div class="col-md-6">
                                                                                <input type="file"
                                                                                    formControlName="UPLOAD_1"
                                                                                    (change)="changeFile($event,1)"
                                                                                    class="form-control form-control-sm"><span
                                                                                    class="text-blue"><small>(The
                                                                                        previous education
                                                                                        support
                                                                                        documents should be scanned and
                                                                                        uploaded as a
                                                                                        single
                                                                                        pdf file.)</small></span>
                                                                            </div>
                                                                        </div>

                                                                        <div class="row mb-2">
                                                                            <div class="col-md-6">Are you applying for
                                                                                <b>Recognition of
                                                                                    Prior Learning (RPL)?</b>
                                                                            </div>
                                                                            <div class="col-md-6">
                                                                                <select
                                                                                    class="form-control form-control-sm"
                                                                                    formControlName="IS_RPL">
                                                                                    <option value="">Select</option>
                                                                                    <option value="true">Yes</option>
                                                                                    <option value="False">No</option>
                                                                                </select>
                                                                                <small>(dropdown: YES/NO)</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-6">Upload your Portfolio
                                                                                Documents</div>
                                                                            <div class="col-md-6">
                                                                                <div class="input-group">
                                                                                    <input type="file"
                                                                                        formControlName="UPLOAD_3"
                                                                                        (change)="changeFile($event,3)"
                                                                                        class="form-control"
                                                                                        placeholder="Recipient's username"
                                                                                        aria-label="Recipient's username"
                                                                                        aria-describedby="basic-addon2"
                                                                                        #fileInputRef>
                                                                                    <span class="input-group-text"
                                                                                        id="basic-addon2"
                                                                                        (click)="removeFile()">Remove</span>
                                                                                </div>
                                                                                <div class="text-blue"><small>(If you
                                                                                        applying for RPL
                                                                                        your
                                                                                        portfolio documents should be
                                                                                        scanned and
                                                                                        uploaded
                                                                                        as a single pdf file.)</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 mt-3">
                                                                            <button type="button"
                                                                                class="float-start btn btn-sm admin-btn btn-sm px-4 rounded-0">Previous</button>
                                                                            <button type="button"
                                                                                data-bs-target="#Next-of-Kin-tab"
                                                                                class="float-end btn btn-sm admin-btn btn-sm px-4 rounded-0">Next</button>
                                                                        </div>
                                                                    </div>
                                                                    <div class="card-footer bg-white mt-3">
                                                                        <div style="width: 35%" class="mb-2"><img
                                                                                src="../../../assets/img/OUK-Admissions-logo.png"
                                                                                class="img-fluid" alt=""></div>
                                                                        <p class="small">OUK is a public university that
                                                                            was established
                                                                            and
                                                                            Chartered in 2023. We
                                                                            aim
                                                                            to provide
                                                                            inclusive and flexible high quality higher
                                                                            education
                                                                            opportunities suited for the global
                                                                            citizens in the
                                                                            21st century. At OUK admissions, we recruit
                                                                            and enroll
                                                                            diverse
                                                                            class of students.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="application-info-tab" role="tabpanel"
                                                aria-labelledby="application-info">
                                                <div class="card border-0 card-shadow h-100">
                                                    <div class="card-body assesment pt-0 style-1 ">
                                                        <div class="row">
                                                            <div class="col-md-8 m-auto">
                                                                <div class="card border-0 card-shadow h-100">
                                                                    <div class="card-body p-4">
                                                                        <div
                                                                            class="card_title mb-3 px-2 py-1 text-white">
                                                                            <h6 class="mb-0">What would you like to
                                                                                study?</h6>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Programme <span
                                                                                    class="text-danger">*</span></div>
                                                                            <div class="col-md-6">
                                                                                <select [(ngModel)]="courseId"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    disabled
                                                                                    class="form-control form-control-sm">
                                                                                    <option value="">Select</option>
                                                                                    <option
                                                                                        *ngFor="let item of  courseList"
                                                                                        [value]="item.COURSE_ID">
                                                                                        {{item.COURSE_NAME}}
                                                                                    </option>
                                                                                </select>
                                                                                <span class="text-blue"><small>(the
                                                                                        field of
                                                                                        study/programme
                                                                                        you are applying for in this
                                                                                        application)</small></span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Application Type <span
                                                                                    class="text-danger">*</span></div>
                                                                            <div class="col-md-6">
                                                                                <select
                                                                                    class="form-control form-control-sm"
                                                                                    formControlName="APPLICATION_TYPE">
                                                                                    <option value="">Select</option>
                                                                                    <option value="1">Undergraduate
                                                                                    </option>
                                                                                    <option value="2">Postgraduate
                                                                                    </option>
                                                                                </select>
                                                                                <!-- <input type="text" formControlName="APPLICATION_TYPE"
                                                                                    class="form-control form-control-sm"> -->
                                                                                <span class="text-blue"><small>(e.g.
                                                                                        Undergraduate or
                                                                                        Postgraduate)</small></span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Basic Education System
                                                                                <span class="text-danger">*</span>
                                                                            </div>
                                                                            <div class="col-md-6">
                                                                                <select
                                                                                    class="form-control form-control-sm"
                                                                                    formControlName="BASIC_EDUCATION_SYSTEM">
                                                                                    <option value="">Select</option>
                                                                                    <option value="1">KCSE</option>
                                                                                    <option value="2">IGCSE</option>
                                                                                    <option value="3">GCSE</option>
                                                                                    <option value="4">IB</option>
                                                                                    <option value="5">Others</option>
                                                                                </select>
                                                                                <span class="text-blue"><small>(e.g.
                                                                                        education system
                                                                                        that
                                                                                        describes your secondary school
                                                                                        education
                                                                                        journey)</small></span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Mean Grade
                                                                                <span class="text-danger">*</span>
                                                                            </div>
                                                                            <div class="col-md-6">
                                                                                <input type="text"
                                                                                    formControlName="MEAN_GRADE">
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Programme Cluster
                                                                            </div>
                                                                            <div class="col-md-6">
                                                                                <span class="text-blue"><small>(for
                                                                                        undergraduates
                                                                                        provide
                                                                                        relevant cluster subjects and
                                                                                        grades based on
                                                                                        KUCCPS
                                                                                        guidelines)</small></span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <table class="table table-bordered">
                                                                                    <tr>
                                                                                        <th></th>
                                                                                        <th>Subject Name(eg MAT)</th>
                                                                                        <th>Grade obtained</th>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>CLUSTER SUBJECT 1</td>
                                                                                        <td><input type="text"
                                                                                                formControlName="CLUSTER_SUBJECT1_NAME"
                                                                                                class="form-control form-control-sm">
                                                                                        </td>
                                                                                        <td><input type="text"
                                                                                                formControlName="CLUSTER_SUBJECT1_GRADE"
                                                                                                class="form-control form-control-sm">
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>CLUSTER SUBJECT 2</td>
                                                                                        <td><input type="text"
                                                                                                formControlName="CLUSTER_SUBJECT2_NAME"
                                                                                                class="form-control form-control-sm">
                                                                                        </td>
                                                                                        <td><input type="text"
                                                                                                formControlName="CLUSTER_SUBJECT2_GRADE"
                                                                                                class="form-control form-control-sm">
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>CLUSTER SUBJECT 3</td>
                                                                                        <td><input type="text"
                                                                                                formControlName="CLUSTER_SUBJECT3_NAME"
                                                                                                class="form-control form-control-sm">
                                                                                        </td>
                                                                                        <td><input type="text"
                                                                                                formControlName="CLUSTER_SUBJECT3_GRADE"
                                                                                                class="form-control form-control-sm">
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>CLUSTER SUBJECT 4</td>
                                                                                        <td><input type="text"
                                                                                                formControlName="CLUSTER_SUBJECT4_NAME"
                                                                                                class="form-control form-control-sm">
                                                                                        </td>
                                                                                        <td><input type="text"
                                                                                                formControlName="CLUSTER_SUBJECT4_GRADE"
                                                                                                class="form-control form-control-sm">
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">English <span
                                                                                    class="text-danger">*</span>
                                                                            </div>
                                                                            <div class="col-md-6">
                                                                                <div class="dropdown">
                                                                                    <select
                                                                                        class="form-control form-control-sm"
                                                                                        formControlName="ENGLISH_PROFICIENCY">
                                                                                        <option value="">Select Level
                                                                                        </option>
                                                                                        <option value="1">Beginner
                                                                                        </option>
                                                                                        <option value="2">Intermediate
                                                                                        </option>
                                                                                        <option value="3">Advanced
                                                                                        </option>
                                                                                        <option value="4">Mastery
                                                                                        </option>
                                                                                    </select>

                                                                                </div>
                                                                                <span class="text-blue small">(your
                                                                                    language proficiency
                                                                                    level in both speaking and
                                                                                    writing)</span>
                                                                            </div>

                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Computing Skills <span
                                                                                    class="text-danger">*</span></div>
                                                                            <div class="col-md-6">
                                                                                <div class="dropdown">
                                                                                    <select
                                                                                        class="form-control form-control-sm"
                                                                                        formControlName="COMPUTING_SKILLS_PROFICIENCY">
                                                                                        <option value="">Select Level
                                                                                        </option>
                                                                                        <option value="1">Beginner
                                                                                        </option>
                                                                                        <option value="2">Intermediate
                                                                                        </option>
                                                                                        <option value="3">Advanced
                                                                                        </option>
                                                                                        <option value="4">Mastery
                                                                                        </option>
                                                                                    </select>
                                                                                </div>
                                                                                <span class="text-blue small">(describe
                                                                                    your computer
                                                                                    handling skills)</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-4">Mode of Study <span
                                                                                    class="text-danger">*</span></div>
                                                                            <div class="col-md-6"><input type="text"
                                                                                    formControlName="MODE_OF_STUDY"
                                                                                    class="form-control form-control-sm">
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-12 mt-3">
                                                                            <button type="button"
                                                                                class="float-start btn btn-sm admin-btn btn-sm px-4 rounded-0">Previous</button>
                                                                            <button type="button"
                                                                                class="float-end btn btn-sm admin-btn btn-sm px-4 rounded-0">Next</button>
                                                                        </div>
                                                                    </div>
                                                                    <div class="card-footer bg-white mt-3">
                                                                        <div style="width: 35%" class="mb-2"><img
                                                                                src="../../../assets/img/OUK-Admissions-logo.png"
                                                                                class="img-fluid" alt=""></div>
                                                                        <p class="small">OUK is a public university that
                                                                            was established
                                                                            and
                                                                            Chartered in 2023. We
                                                                            aim
                                                                            to provide
                                                                            inclusive and flexible high quality higher
                                                                            education
                                                                            opportunities suited for the global
                                                                            citizens in the
                                                                            21st century. At OUK admissions, we recruit
                                                                            and enroll
                                                                            diverse
                                                                            class of students.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="Working-Exp-tab" role="tabpanel"
                                                aria-labelledby="Working-Exp">
                                                <div class="card border-0 card-shadow h-100">
                                                    <div class="card-body assesment pt-0 style-1 ">
                                                        <div class="row">
                                                            <div class="col-md-8 m-auto">
                                                                <div class="card border-0 card-shadow h-100">
                                                                    <div class="card-body p-4">
                                                                        <div
                                                                            class="card_title mb-3 px-2 py-1 text-white">
                                                                            <h6 class="mb-0">Working/Job Experience</h6>
                                                                        </div>
                                                                        <div
                                                                            *ngFor="let row of ApplicantData;let i = index">
                                                                            <div class="row mb-2">
                                                                                <div class="col-md-4">Company/Employer
                                                                                </div>
                                                                                <div class="col-md-6"> <input
                                                                                        type="text"
                                                                                        [(ngModel)]="row.WORKEXP_DETAIL_EMPLOYER"
                                                                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                                        [ngModelOptions]="{ standalone: true }"
                                                                                        class="form-control form-control-sm">
                                                                                    <span class="text-blue"><small>(the
                                                                                            field of
                                                                                            study/programme
                                                                                            you are applying for in this
                                                                                            application)</small></span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-2">
                                                                                <div class="col-md-4">Start Date</div>
                                                                                <div class="col-md-6"> <input
                                                                                        type="date"
                                                                                        [(ngModel)]="row.WORKEXP_DETAIL_FROM_YEAR"
                                                                                        [ngModelOptions]="{ standalone: true }"
                                                                                        class="form-control form-control-sm">
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-2">
                                                                                <div class="col-md-4">End Date</div>
                                                                                <div class="col-md-6"> <input
                                                                                        type="date"
                                                                                        [(ngModel)]="row.WORKEXP_DETAIL_TO_YEAR"
                                                                                        [ngModelOptions]="{ standalone: true }"
                                                                                        class="form-control form-control-sm">
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-2">
                                                                                <div class="col-md-4">Designation</div>
                                                                                <div class="col-md-6"> <input
                                                                                        type="text"
                                                                                        [(ngModel)]="row.WORKEXP_DETAIL_DESIGNATION"
                                                                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                                        [ngModelOptions]="{ standalone: true }"
                                                                                        class="form-control form-control-sm">
                                                                                </div>
                                                                                <div
                                                                                    class="col-xl-3 col-md-6 col-12 mb-3 pt-4">
                                                                                    <button
                                                                                        class="btn btn-danger btn-sm"
                                                                                        (click)="removeApplicantDetails(i)">Remove</button>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-2">
                                                                                <div class="col-md-12 text-center">
                                                                                    <button type="button"
                                                                                        class="btn btn-sm admin-btn btn-sm px-4 rounded-0"
                                                                                        (click)="addApplicantDetails()">Add</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-3">
                                                                        <button type="button"
                                                                            class="float-start btn btn-sm admin-btn btn-sm px-4 rounded-0">Previous</button>
                                                                        <button type="button"
                                                                            class="float-end btn btn-sm admin-btn btn-sm px-4 rounded-0">Next</button>
                                                                    </div>
                                                                </div>
                                                                <div class="card-footer bg-white mt-3">
                                                                    <div style="width: 35%" class="mb-2"><img
                                                                            src="../../../assets/img/OUK-Admissions-logo.png"
                                                                            class="img-fluid" alt="">
                                                                    </div>
                                                                    <p class="small">OUK is a public university that was
                                                                        established and
                                                                        Chartered in 2023. We
                                                                        aim
                                                                        to provide
                                                                        inclusive and flexible high quality higher
                                                                        education
                                                                        opportunities
                                                                        suited for the global
                                                                        citizens in the
                                                                        21st century. At OUK admissions, we recruit and
                                                                        enroll diverse
                                                                        class
                                                                        of students.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="Recommendations-tab" role="tabpanel"
                                                aria-labelledby="Recommendations">
                                                <div class="card border-0 card-shadow h-100">
                                                    <div class="card-body assesment pt-0 style-1 ">
                                                        <div class="row">
                                                            <div class="col-md-8 m-auto">
                                                                <div class="card border-0 card-shadow h-100">
                                                                    <div class="card-body p-4">
                                                                        <div
                                                                            class="card_title mb-3 px-2 py-1 text-white">
                                                                            <h6 class="mb-0">Recommendations</h6>
                                                                        </div>
                                                                        <div
                                                                            *ngFor="let row of RefreeData;let i = index">


                                                                            <div class="row mb-2">
                                                                                <div class="col-md-4">Full Name</div>
                                                                                <div class="col-md-6"><input type="text"
                                                                                        [(ngModel)]="row.NAME"
                                                                                        [ngModelOptions]="{standalone:true}"
                                                                                        class="form-control form-control-sm">
                                                                                    <span class="text-blue"><small>(the
                                                                                            field of
                                                                                            study/programme
                                                                                            you are applying for in this
                                                                                            application)</small></span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-2">
                                                                                <div class="col-md-4">Organization</div>
                                                                                <div class="col-md-6"><input type="text"
                                                                                        [(ngModel)]="row.ORGANIZATION"
                                                                                        [ngModelOptions]="{standalone:true}"
                                                                                        class="form-control form-control-sm">
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-2">
                                                                                <div class="col-md-4">Mobile Number
                                                                                </div>
                                                                                <div class="col-md-6"><input
                                                                                        type="number"
                                                                                        [(ngModel)]="row.MOBILE_NUMBER"
                                                                                        [ngModelOptions]="{standalone:true}"
                                                                                        class="form-control form-control-sm">
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-2">
                                                                                <div class="col-md-4">City/Town</div>
                                                                                <div class="col-md-6"><input type="text"
                                                                                        [(ngModel)]="row.CITY_TOWN"
                                                                                        [ngModelOptions]="{standalone:true}"
                                                                                        class="form-control form-control-sm">
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-2">
                                                                                <div class="col-md-4">Nationality</div>
                                                                                <div class="col-md-6"><input type="text"
                                                                                        [(ngModel)]="row.NATIONALITY"
                                                                                        [ngModelOptions]="{standalone:true}"
                                                                                        class="form-control form-control-sm">
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-2">
                                                                                <div class="col-md-4">Email Address
                                                                                </div>
                                                                                <div class="col-md-6"><input type="text"
                                                                                        [(ngModel)]="row.EMAIL_ADDRESS"
                                                                                        [ngModelOptions]="{standalone:true}"
                                                                                        class="form-control form-control-sm">
                                                                                </div>

                                                                            </div>
                                                                            <div
                                                                                class="col-xl-3 col-md-6 col-12 mb-3 pt-4">
                                                                                <button class="btn btn-danger btn-sm "
                                                                                    (click)="removeRefreeData(i)">Remove</button>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 mt-3">
                                                                            <button type="button"
                                                                                class="btn btn-sm admin-btn btn-sm px-4 rounded-0"
                                                                                (click)="addRefreeData()">Add</button>
                                                                            <button type="button"
                                                                                class="float-start btn btn-sm admin-btn btn-sm px-4 rounded-0">Previous</button>
                                                                            <button type="button"
                                                                                class="float-end btn btn-sm admin-btn btn-sm px-4 rounded-0">Next</button>
                                                                        </div>
                                                                    </div>
                                                                    <div class="card-footer bg-white mt-3">
                                                                        <div style="width: 35%" class="mb-2"><img
                                                                                src="../../../assets/img/OUK-Admissions-logo.png"
                                                                                class="img-fluid" alt=""></div>
                                                                        <p class="small">OUK is a public university that
                                                                            was established
                                                                            and
                                                                            Chartered in 2023. We
                                                                            aim
                                                                            to provide
                                                                            inclusive and flexible high quality higher
                                                                            education
                                                                            opportunities suited for the global
                                                                            citizens in the
                                                                            21st century. At OUK admissions, we recruit
                                                                            and enroll
                                                                            diverse
                                                                            class of students.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="Review-tab" role="tabpanel"
                                                aria-labelledby="Review">
                                                <div class="card border-0 card-shadow h-100">
                                                    <div class="card-body assesment pt-0 style-1 ">
                                                        <div class="row">
                                                            <div class="col-md-8 m-auto">
                                                                <div class="card border-0 card-shadow h-100">
                                                                    <div class="card-body p-4">
                                                                        <div
                                                                            class="card_title mb-3 px-2 py-1 text-white">
                                                                            <h6 class="mb-0">Review and submit</h6>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-12">Remember that once
                                                                                you submit your
                                                                                application you cannot make any
                                                                                additional changes or
                                                                                view
                                                                                the application.</div>
                                                                        </div>
                                                                        <div
                                                                            class="card_title mb-3 px-2 py-1 text-white">
                                                                            <h6 class="mb-0 text-center">Review your
                                                                                filled application
                                                                                form
                                                                                here</h6>
                                                                        </div>
                                                                        <div class="row mb-2">
                                                                            <div class="col-md-12 p-4 h4 text-blue text-center"
                                                                                style="background-color: #CBCBCB;">
                                                                                (System should
                                                                                display
                                                                                the whole form here)</div>
                                                                        </div>
                                                                        <div class="col-12 mt-3">
                                                                            <button type="button"
                                                                                class="float-start btn btn-sm admin-btn btn-sm px-4 rounded-0">Previous</button>
                                                                            <button type="button"
                                                                                class="float-end btn btn-sm admin-btn-blue btn-sm px-4 rounded-0"
                                                                                (click)="save(signUpForm,'1')">Submit
                                                                                to Admissions</button>
                                                                            <button type="button"
                                                                                class="float-end btn btn-sm admin-btn btn-sm px-4 rounded-0"
                                                                                (click)="save(signUpForm,'0');">Save
                                                                                for Later</button>

                                                                        </div>
                                                                    </div>
                                                                    <div class="card-footer bg-white mt-3">
                                                                        <div style="width: 35%" class="mb-2"><img
                                                                                src="../../../assets/img/OUK-Admissions-logo"
                                                                                class="img-fluid" alt=""></div>
                                                                        <p class="small">OUK is a public university that
                                                                            was established
                                                                            and
                                                                            Chartered in 2023. We
                                                                            aim
                                                                            to provide
                                                                            inclusive and flexible high quality higher
                                                                            education
                                                                            opportunities suited for the global
                                                                            citizens in the
                                                                            21st century. At OUK admissions, we recruit
                                                                            and enroll
                                                                            diverse
                                                                            class of students.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<script>
    $('.btnNext').click(function () {
        const nextTabLinkEl = $('.nav-tabs .active').closest('li').next('li').find('a')[0];
        const nextTab = new bootstrap.Tab(nextTabLinkEl);
        nextTab.show();
    });

    $('.btnPrevious').click(function () {
        const prevTabLinkEl = $('.nav-tabs .active').closest('li').prev('li').find('a')[0];
        const prevTab = new bootstrap.Tab(prevTabLinkEl);
        prevTab.show();
    });
</script>