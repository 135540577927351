<!DOCTYPE html>
<html lang="en">


    <head>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Bootstrap 5 404 Error Page</title>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet">
    </head>


    <body style="background-color: #037b90;">
        <div class="d-flex align-items-center justify-content-center vh-100">
            <div class="text-center">
                <h1 class="fw-2" style="color: white ">Unauthorized Access</h1>
                <!-- <p class="fs-6"> Please contact administrator.</p> -->
                <!-- <p class="lead">
                    The page you’re looking for doesn’t exist.
                  </p> -->
                <a href="login" class="btn btn-primary">Go Home</a>
            </div>
        </div>
    </body>