import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ChatService } from 'src/app/services/chat.service';
import { ChatMessageDto, IUserInfo } from 'src/app/models/chat-message-dto';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-load-chat-data',
  templateUrl: './load-chat-data.component.html',
  styleUrls: ['./load-chat-data.component.css']
})
export class LoadChatDataComponent implements OnInit, OnDestroy {
  TeachersCount: number = 0;
  studentCount: number = 0;
  onlineTeachers: Array<any> = [];
  offlineTeachers: Array<any> = [];
  onlineStudents: Array<any> = [];
  chatMessage: string = '';
  dataRefresher: NodeJS.Timeout;
  roleId = sessionStorage.RoleId;

  userInfo: IUserInfo = { student_id: "", student_name: "", teacher_id: "", teacher_name: "", message: "", usertype: "", curentDate: new Date() };
  chatMessagesList: Array<IUserInfo> = [];
  studentList: Array<{ student_id: string, student_name: string }> = [];
  myChatList: Array<IUserInfo> = [];
  isChatModelOpened: boolean = false;

  courseList: Array<any> = [];
  CourseIdsList: Array<any> = [];
  chatHistoryList: Array<{ id: string, chatHistory: Array<IUserInfo> }> = [];
  clearOnlineTrainers = null;
  chat_id: string = "";
  private subscriptions = new Subscription();
  name: any;

  constructor(private CommonService: CommonService, private chatService: ChatService, private toastr: ToastrService) { }
  ngOnDestroy(): void {
    clearInterval(this.clearOnlineTrainers);
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    const { RoleId = 0 } = sessionStorage;
    Promise.all([this.getExistingTeachersOrStudents()]).then(res => this.isStudent());
    const sub1 = this.chatService.getMessages().subscribe((res: IUserInfo) => {

      if (res) {
        const chatInfo = { ...res, curentDate: new Date() };
        this.insertOrUpdateChatHistory(chatInfo);

        if (+RoleId == 2) {
          if (this.studentList.findIndex(e => e.student_id == res.student_id) == -1) {
            this.studentList.push({ student_id: res.student_id, student_name: res.student_name });
            this.studentList = [...this.studentList];
            this.studentCount = this.studentList.length;
          }
        }
        if (!this.isChatModelOpened)
          this.loadChat({ ...res, doctorId: `tea_${res.teacher_id}`, name: res.teacher_name });
      }
    });
    this.subscriptions.add(sub1);

  }

  insertOrUpdateChatHistory(chatInfo: IUserInfo) {
    const new_id = `${chatInfo.teacher_id}_${chatInfo.student_id}`;
    const index: number = this.chatHistoryList.findIndex(e => e.id == new_id);
    if (index > -1) {
      this.chatHistoryList[index].chatHistory = [...this.chatHistoryList[index].chatHistory, chatInfo];
      this.chatHistoryList = [...this.chatHistoryList];
    }
    else
      this.chatHistoryList.push({ id: new_id, chatHistory: [chatInfo] });

    this.getChatList(new_id);
  }

  getExistingTeachersOrStudents() {
    const { UserId = 0, RoleId = 0 } = sessionStorage;
    const payload = (+RoleId == 2) ? { teacherid: UserId } : { studentid: UserId };
    const Sub_ULR = `Account/${(+RoleId == 2) ? 'LoadStudentsByTeacherid' : 'LoadTeachersByStudentid'}`;
    this.CommonService.postCall(Sub_ULR, payload).subscribe(res => {
      if (+RoleId == 2) {
        res.forEach(st => this.studentList.push({ student_id: st.studentid, student_name: st.studentname }));
        this.studentList = [...this.studentList];
        this.studentCount = this.studentList.length;
        console.log("LoadStudentsByTeacherid", res);
        //Loading existing chat history for student side
        this.studentList.forEach(async e => await this.loadExistingChatHistory(+e.student_id));
      }
      else {
        res.forEach(st => this.offlineTeachers.push({ doctorId: `tea_${st.teacherid}`, name: st.teachername, isOnline: false }));
        this.onlineTeachers = [...this.offlineTeachers];
        this.TeachersCount = this.offlineTeachers.length;
        console.log("LoadTeachersByStudentid", res);
        //Loading existing chat history for teacher side
        this.offlineTeachers.forEach(e => this.loadExistingChatHistory(+e.doctorId.split('_')[1]));
      }
    });
  }

  loadExistingChatHistory(id: number) {
    let { UserId = 0, RoleId = 0, teacherid = 0, studentid = 0 } = sessionStorage;
    if (+RoleId == 3)
      [teacherid, studentid] = [id, UserId];
    else if (+RoleId == 2)
      [teacherid, studentid] = [UserId, id];

    this.CommonService.postCall("Account/LoadStudentTeacherChatHistory", { teacherid, studentid }).subscribe(res => {
      // setTimeout(() => {
      if (res && res.length > 0) {
        const new_id: string = `${res[0].teacherid}_${res[0].studentid}`;
        this.chatHistoryList.push({
          id: new_id, chatHistory: res.map(e => ({
            student_id: e.studentid,
            student_name: e.studentname,
            teacher_id: e.teacherid,
            teacher_name: e.teachername,
            usertype: e.usertype,
            message: e.message,
            curentDate: e.createdon
          }))
        });
      }

      console.log("LoadStudentTeacherChatHistory", this.chatHistoryList);
      // }, 100);
    });
  }

  isStudent() {
    const { TenantCode = 0, UserId = 0, RoleId = 0 } = sessionStorage;

    if (RoleId == 3) {
      this.CommonService.postCall("Courses/LoadCoursesByStudent", { USER_ID: UserId, TNT_CODE: TenantCode }).subscribe(rep => {

        if (rep) {
          this.courseList = rep.map(e => ({ courseId: e.COURSE_ID, courseName: e.COURSE_NAME }));
          this.CourseIdsList = [];
          this.courseList = this.courseList.filter(e => {
            const isNotExist: boolean = !this.CourseIdsList.includes(e.courseId);
            if (isNotExist)
              this.CourseIdsList.push(e.courseId);
            return isNotExist;
          });
          // this.clearOnlineTrainers = setInterval(() => this.getOnlineTeachers(+TenantCode), 10000);
        }
      });
    }
  }

  getOnlineTeachers(TenantCode: number) {
    this.CommonService.getData(`telecon/doctorstatus/getall/?TENANT_ID=${TenantCode}`).subscribe(res => {
      let new_onlineTeachers: Array<any> = res.filter(e => e.courseInfo?.map(ee => ee.courseId).some(m => this.CourseIdsList.includes(m)));
      // [this.onlineTeachers, this.teachersCount] = [res, res.length];

      new_onlineTeachers = new_onlineTeachers.filter(e => !this.offlineTeachers.map(m => m.doctorId.split('_')[1]).includes(e.doctorId.split('_')[1])).map(e => ({ ...e, isOnline: true }));
      if (new_onlineTeachers.length > 0)
        this.onlineTeachers = [...this.offlineTeachers, ...new_onlineTeachers];
      else
        this.onlineTeachers = [...this.offlineTeachers];//this.onlineTeachers.filter(e => e.isOnline == false);

      this.TeachersCount = this.onlineTeachers.length;
    });
  }

  getOnlineStudent(TenantCode: number) {
    [this.studentCount, this.onlineStudents] = [0, []];
    let nameArray = this.chatService.chatNames;
    [this.onlineStudents, this.studentCount] = [nameArray, nameArray.length];
  }

  loadChat(data: any, isNewChatList: boolean = false) {

    if (!this.isChatModelOpened) {
      this.isChatModelOpened = true;
      setTimeout(() => {
        document.getElementById("btnShowChatModel")?.click();
        this.setFocus();
        const ele: any = document.getElementsByClassName("modal-backdrop show");
        if (ele.length > 0)
          ele[0].classList = "";
      });
    }
    const { RoleId = 0, UserId = 0, Username = "" } = sessionStorage;
    // const ele: any = document.getElementsByClassName("modal-backdrop show");
    // if (ele.length > 0)
    //   ele[0].classList = "";

    if (+RoleId == 2) {
      this.userInfo.teacher_id = UserId;
      this.userInfo.teacher_name = Username;
      this.userInfo.student_name = data.student_name;
      this.userInfo.student_id = data.student_id;
      this.userInfo.usertype = "Teacher";
      this.name = data.student_name;
    } else if (+RoleId == 3) {
      let id = data.doctorId.split('_');
      this.userInfo.teacher_id = id[1];
      this.userInfo.teacher_name = data.name;
      this.userInfo.student_name = Username;
      this.userInfo.student_id = UserId;
      this.userInfo.usertype = "Student";
      this.name = data.name;
    }
    if (isNewChatList)
      this.getChatList(`${this.userInfo.teacher_id}_${this.userInfo.student_id}`);
  }

  getChatList(new_id: string) {
    console.log([...this.chatHistoryList]);
    this.chatMessagesList = this.chatHistoryList.filter(e => e.id == new_id)?.[0]?.chatHistory || [];
  }

  close() {
    this.isChatModelOpened = false;
  }

  sendMessage(ev: any) {
    const { RoleId = 0, TenantCode = 0, UserId = 0, Username = "" } = sessionStorage;
    let chatMessageDto: ChatMessageDto;

    this.chatMessage = (this.chatMessage || '').replace('\n', '').trim();

    if (this.chatMessage == '') {
      this.setFocus();
      return;
    }

    this.userInfo.message = this.chatMessage;
    this.userInfo.curentDate = new Date();
    this.insertOrUpdateChatHistory({ ...this.userInfo });

    if (+RoleId == 2)
      chatMessageDto = new ChatMessageDto(TenantCode, UserId, this.userInfo.student_id, '', this.chatMessage, 'Teacher', this.userInfo.student_name, 'register', Username);
    else if (+RoleId == 3)
      chatMessageDto = new ChatMessageDto(TenantCode, this.userInfo.teacher_id, this.userInfo.student_id, '', this.chatMessage, 'Student', this.userInfo.student_name, 'register', this.userInfo.teacher_name);

    this.chatService.sendMessage(chatMessageDto);
    this.chatMessage = '';
    // this.refreshChat();
  }

  // refreshChat() {
  //   this.dataRefresher =
  //     setInterval(() => {
  //       if (this.chatService.webSocket?.readyState != 3) {
  //         this.chatService.sendMessage2('ping');
  //       }
  //     }, 15000);
  // }

  ChatKeyPress(event: any) {
    event.charCode == 13 && this.sendMessage(null);
  }

  setFocus() {
    document.getElementById("search_string")?.focus();
  }

}
