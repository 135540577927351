import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { IPaginationTable } from './PaginationTable';
import * as moment from 'moment';

@Component({
  selector: 'app-pagination-table',
  templateUrl: './pagination-table.component.html',
  styleUrls: ['./pagination-table.component.css']
})
export class PaginationTableComponent implements OnInit, OnChanges {
  @Input() PaginationData: IPaginationTable;
  @Output() onSearch = new EventEmitter<string>();
  @Output() editItem = new EventEmitter<any>();
  @Output() addItem = new EventEmitter<any>();
  @Output() rowClickable = new EventEmitter<any>();

  paginatedData: any[] = [];
  currentPage: number = 1;
  totalPages: number = 1;
  searchTerm: string = '';
  totalAmount: number = 0;
  startEntry: number = 0;
  endEntry: number = 0;
  constructor() { }

  ngOnInit(): void {
    if (this.PaginationData) {
      this.updatePagination();
      if (this.PaginationData.showTotal) {
        this.calculateTotal();
      }
    }
  }

  ngOnChanges(): void {
    if (this.PaginationData) {
      this.updatePagination();
      if (this.PaginationData.showTotal) {
        this.calculateTotal();
      }
    }
  }

  updatePagination(): void {
    const startIndex = (this.currentPage - 1) * this.PaginationData.itemsPerPage;
    const endIndex = startIndex + this.PaginationData.itemsPerPage;
    this.paginatedData = this.PaginationData.DataList.slice(startIndex, endIndex).map(item => {
      const formattedItem = { ...item }; // Create a copy of the item
      for (const key in formattedItem) {
        if (formattedItem.hasOwnProperty(key)) {
          const value = formattedItem[key];
          // Check if the value is a valid date
          if (typeof value === 'string' && isNaN(Number(value)) && moment(value, moment.ISO_8601, true).isValid()) {
            formattedItem[key] = moment(value).format('YYYY-MM-DD'); // Format the date
          }
        }
      }
      return formattedItem; // Return the formatted item
    });
    this.totalPages = Math.ceil(this.PaginationData.DataList.length / this.PaginationData.itemsPerPage);

    this.startEntry = startIndex + 1;
    this.endEntry = Math.min(endIndex, this.PaginationData.DataList.length);
  }

  calculateTotal(): void {
    this.totalAmount = this.PaginationData.DataList.reduce((total, item) => total + +item.amount, 0);
  }

  changePage(page: number): void {
    this.currentPage = page;
    this.updatePagination();
  }

  onSearchInput(): void {
    this.onSearch.emit(this.searchTerm);
    this.currentPage = 1;
    this.updatePagination();
  }

  onEdit(item: any): void {
    this.editItem.emit(item);
  }

  onAdd(): void {
    this.addItem.emit();
  }

  rowClick(item): void {
    if (this.PaginationData.isClickableRow)
      this.rowClickable.emit(item);
  }
}
