import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/pages/base.component';
import { CommonService } from 'src/app/services/common.service';
import { SamvaadUserRegister } from 'src/app/samvaad-user.component';
import { environment } from 'src/environments/environment';
import * as moment from 'moment-timezone';
import { forkJoin } from 'rxjs';
import { FileuploadService } from '../../services/fileupload.service';
import { dataDictionary } from 'src/app/dataDictionary';
declare let $: any;
@Component({
  selector: 'app-student-registration-new',
  templateUrl: './student-registration-new.component.html',
  styleUrls: ['./student-registration-new.component.css']
})
export class StudentRegistrationNewComponent  extends BaseComponent  implements OnInit {

  signUpForm: FormGroup;
  submitted: boolean = false;
  RegUser = new SamvaadUserRegister();
  samvaadUserPWD: string = '';
  RoleId = sessionStorage.getItem('RoleId');
  companyList: Array<any> = [];
  pay_Load: any = {};
  questionsList: Array<any> = [];
  isLoginDetailsSubmitted: boolean = false;
  questionsCard: boolean = false;
  signupCard: boolean = true;
  gender: Array<any> = [];
  selectedAnswers: any[] = [];
  backBtn: boolean = false;
  addressCountry: Array<any> = [];
  inputFld: boolean = false;
  upload1: File;
  upload2: File;
  upload3: File;
  upload4: File;
  upload5: File;
  fileName1: any;
  fileName2: any; fileName3: any; fileName4: any; fileName5: any;
  constructor(private fb: FormBuilder, CommonService: CommonService,
    private route: Router, private FileuploadService: FileuploadService,
    toastr: ToastrService) {
    super(CommonService, toastr);
    // this.getCompanyDetails();
  }

  ngOnInit(): void {
    // this.validationInit();
    this.signUpOnInit();
    this.getAll();
    this.loadQuestions();
    // this.getCompanyList();
    // setTimeout(() => this.companyDetails(), 10);
  }



  get f() { return this.signUpForm.controls; }
  // ---------------------------------------New Sign Up Form---------------------------------
  signUpOnInit() {
    this.signUpForm = this.fb.group({
      FirstName: ['', [Validators.required, Validators.maxLength(100)]],
      LastName: ['', [Validators.required, Validators.maxLength(100)]],
      middle_name: [''],
      id_passport: ['', Validators.required],
      Mobileno: ['', [Validators.required, Validators.pattern("^[0-9]*$"),
      Validators.minLength(12), Validators.maxLength(12)]],
      UserName: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]],
      Gender: [''],
      country_id: [''],
      specify_highest_qualification: [''],
      programme_category_id: [''],
      course_interested_in: [''],
      comment: [''],
      upload_1: [''],
      upload_2: [''],
      upload_3: [''],
      upload_4: [''],
      upload_5: [''],
    })
  }
  // create registration Method
  save() {
    this.activeSpinner();
    let payload = this.signUpForm.getRawValue();
    const currentTimeIST = moment().tz('Asia/Kolkata');
    payload.TENANT_CODE = "88968955";
    payload.UserType = "26";
    payload.role = "3";
    payload.Timezone = currentTimeIST.format();
    payload.CREATEDBY = "1";
    payload.upload_1 = this.fileName1 || '';
    payload.upload_2 = this.fileName2 || '';
    payload.upload_3 = this.fileName3 || '';
    payload.upload_4 = this.fileName4 || '';
    payload.upload_5 = this.fileName5 || '';

    this.CommonService.postCall('Registration/SaveRegistration', payload).subscribe((res: any) => {
      if (res.MESSAGE == "Registration Successful") {
        localStorage.setItem('userName', payload.UserName);
        this.questionsCard = true;
        this.backBtn = true;
        this.toastr.success(res.MESSAGE);
        this.deactivateSpinner();
        return
      } else {
        this.toastr.error(res.MESSAGE);
        this.questionsCard = false;
        this.signupCard = true;
        this.deactivateSpinner();
      }
    }, (e: any) => {
      this.deactivateSpinner();
      this.signupCard = true;
      this.questionsCard = false;
    })
  }

  nextSlide() {
    this.questionsCard = true;
    this.signupCard = false;
    this.loadQuestions();
  }
  back() {
    window.history.back();
  }
  getAll() {
    let types = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.Gender });//Gender
    let country = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.Country });
    forkJoin([types, country,]).subscribe((res) => {
      [this.gender, this.addressCountry,] = [...res]
    })

  }

  changeFile(event: any, id: number) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];

      switch (id) {
        case 1:
          this.upload1 = file;
          this.upload(id);
          break;
        case 2:
          this.upload2 = file;
          this.upload(id);
          break;
        case 3:
          this.upload3 = file;
          this.upload(id);
          break;
        case 4:
          this.upload4 = file;
          this.upload(id);
          break;
        default:
          this.upload5 = file;
          this.upload(id);
          break;
      }
    }
  }

  upload(id: number) {
    const formData = new FormData();

    switch (id) {
      case 1:
        formData.append('upload_1', this.upload1);
        break;
      case 2:
        formData.append('upload_2', this.upload2);
        break;
      case 3:
        formData.append('upload_3', this.upload3);
        break;
      case 4:
        formData.append('upload_4', this.upload4);
        break;
      default:
        formData.append('upload_5', this.upload5);
        break;
    }

    formData.append('DIRECTORY_NAME', 'upload_files_questions');
    this.activeSpinner();
    this.FileuploadService.upload(formData, 'Registration/Upload_File').subscribe(
      (res: any) => {
        try {
          if (id == 1) {
            this.fileName1 = res.path;
            this.deactivateSpinner(); return;
          } else if (id == 2) {
            this.fileName2 = res.path; this.deactivateSpinner(); return;
          } else if (id == 3) {
            this.fileName3 = res.path;  this.deactivateSpinner(); return;
          } else if (id == 4) {
            this.fileName4 = res.path; this.deactivateSpinner(); return;
          } else {
            this.fileName5 = res.path;
            this.deactivateSpinner();
          }

          this.deactivateSpinner();
        } catch (e) {
          console.log(e);
        }
      },
      (err) => {
        this.deactivateSpinner();
      }
    );
  }


  submitAnswers() {
    this.activeSpinner();
    let payload =
    {
      "UserName": localStorage.getItem('userName'),
      "TenantCode": 88968955,
      "dlc_userregistration_answer": this.selectedAnswers
    }
    for (const answer of this.selectedAnswers) {
      if (answer.REG_QUESTION_TYPE === 3) {
        answer.SelectedOption = answer.SelectedOption.join(', ');
      }
    }
    this.CommonService.postCall('Registration/CreateUserSubmittedAnswers', payload).subscribe((res: any) => {
      this.toastr.success("Submited successful!");
      setTimeout(() => {
        this.route.navigate(['tutorHome']);
      }, 500);
      this.deactivateSpinner();

    }, (e: any) => {
      this.deactivateSpinner();
    })
  }

  //Questions List APi
  loadQuestions() {
    this.CommonService.postCall('Registration/LoadQuestionsOptions', { 'TNT_CODE': 88968955 }).subscribe((res: any) => {
      this.questionsList = res;
    })
  }


  // Function to handle user input for questions with options
  onAnswerSelected(questionId: number, answerId: string, otherOptn: string, type: any) {
    otherOptn == 'Other' ? this.inputFld = true : this.inputFld = false;
    // Check if an answer for the question already exists in selectedAnswers
    const existingAnswerIndex = this.selectedAnswers.findIndex((answer) => answer.REG_QUESTION_ID === questionId);

    if (existingAnswerIndex !== -1) {
      // If an answer exists, update it with the new selection
      this.selectedAnswers[existingAnswerIndex].SelectedOption = answerId;
    } else {
      // If an answer doesn't exist, add a new answer object to selectedAnswers array
      this.selectedAnswers.push({ Type: type, REG_QUESTION_ID: questionId, SelectedOption: answerId.toString() });
    }
  }
  // Function to handle user input for questions with no options
  onInputValueChanged(questionId: number, inputValue: string, type: any) {
    // Check if an answer for the question already exists in selectedAnswers
    const existingAnswerIndex = this.selectedAnswers.findIndex((answer) => answer.REG_QUESTION_ID === questionId);

    if (existingAnswerIndex !== -1) {
      // If an answer exists, update it with the new input value
      this.selectedAnswers[existingAnswerIndex].SelectedOption = inputValue;
    } else {
      // If an answer doesn't exist, add a new answer object to selectedAnswers array
      this.selectedAnswers.push({ Type: type, REG_QUESTION_ID: questionId, SelectedOption: inputValue.toString() });
    }
  }
  // Function to handle checkbox selections for questions with options (REG_QUESTION_TYPE = 3)
  onCheckboxValueChanged(questionId: number, answerId: string, type: any) {
    // Check if an answer for the question already exists in selectedAnswers
    const existingAnswerIndex = this.selectedAnswers.findIndex((answer) => answer.REG_QUESTION_ID === questionId);

    if (existingAnswerIndex !== -1) {
      // If an answer exists, check if the answerId is already in the SelectedOption array
      const selectedOption = this.selectedAnswers[existingAnswerIndex].SelectedOption;
      const indexOfAnswerId = selectedOption.indexOf(answerId);

      if (indexOfAnswerId !== -1) {
        // If the answerId is present, remove it from the SelectedOption array
        selectedOption.splice(indexOfAnswerId, 1);
      } else {
        // If the answerId is not present, add it to the SelectedOption array
        selectedOption.push(answerId);
      }
    } else {
      // If an answer doesn't exist, add a new answer object to selectedAnswers array with the answerId in the SelectedOption array
      this.selectedAnswers.push({ Type: type, REG_QUESTION_ID: questionId, REG_QUESTION_TYPE: this.getQuestionType(questionId), SelectedOption: [answerId] });
    }
  }
  // Function to get the question type (1 for radio, 2 for input, 3 for checkbox)
  getQuestionType(questionId: number): number {
    const question = this.questionsList.find((item) => item.REG_QUESTION_ID === questionId);
    return question ? question.REG_QUESTION_TYPE : -1;
  }
  // --------------------------------------------------------------------------------------------




  onSubmit() {
    this.submitted = true;
    // if (this.signUpForm.invalid) {
    //   this.toastr.warning('Please Enter All Mandatory Fields', 'SignUp');
    //   return;
    // }

    let signUpData = this.signUpForm.getRawValue();
    if (!signUpData.role) {//Tutor
      if ((+signUpData.regType == 2) && (signUpData.Company_Name.trim() == "")) {
        this.toastr.warning("Please enter the company name");
        document.getElementById('Company_Name').focus();
        return;
      }
    }
    else {
      if ((+signUpData.regType == 2) && (signUpData.Company_id == 0)) {
        this.toastr.warning('Please select company name.');
        return;
      }
    }
    //console.log('signup user :', this.signUpForm.getRawValue().trim());
    let [payLoad, URL] = [{}, ''];


    const TENANT_CODE = this.getTenantCode(+sessionStorage.company_id);
    if (!signUpData.role) {//Tutor Level
      URL = 'TenantRegistration/Create';
      payLoad = {
        TNT_NAME: signUpData.name,
        TNT_STATUS: true,
        TenantLogoUrl: "",
        SHOWLOGO: false,
        allow_proctoring: false,
        allow_exampad: false,
        TNT_CREATEDBY: "12345678",
        MobileNo: signUpData.mobile,
        emailid: signUpData.email,
        Company_Name: signUpData.Company_Name || '',
        RoleId: (+signUpData.regType == 1) ? 25 : 24
        // RoleId: 25//Tutor
      };
      console.log(payLoad);
    }
    else {//Student Level
      URL = 'Registration/SaveRegistration';
      payLoad = {
        title: 38,
        firstname: signUpData.name,
        lastname: " ",
        mobileno: signUpData.mobile,
        username: signUpData.email,
        password: "abc123",
        confirmPassword: "abc123",
        dob: "0001-01-01",
        Gender: 1,
        TENANT_CODE: TENANT_CODE,
        UserType: 26,//Student
        // Role: 3,//Member Or Trainee Or Student
        Role: 3,//Member Or Trainee Or Student
        Timezone: "India Standard Time",
        CREATEDBY: TENANT_CODE,
        Company_id: +sessionStorage.company_id
      }
      console.log(payLoad);
    }

    this.pay_Load = payLoad;
    //Open Address Model
    this.getAddressDetails(this.pay_Load);
    this.isLoginDetailsSubmitted = true;


  }

  getAddressDetails(data: any) {
    this.isLoginDetailsSubmitted = false;
    console.log("Address Details:=", data);
    if (data == false || data == null)
      return;
    let URL = 'Registration/SaveRegistration';
    let signUpData = this.signUpForm.getRawValue();

    this.activeSpinner();
    this.CommonService.postCall(URL, this.pay_Load).subscribe(
      (res: any) => {
        console.log(res);

        if (+this.pay_Load.UserType == 26 && res.message == "Registration Successful") {
          data.TENANTCODE = res.TENANT_CODE;
          data.USERID = res.userID;
          this.CommonService.postCall('Account/UpdateAddress', data).subscribe(res => {
            this.toastr.success('Created Successfully');
          });
        }

        if (this.pay_Load['RoleId'] == 25) {
          this.checkSamvaadUserExists(signUpData.email, this.pay_Load, res);

        }
        this.clear();
        this.deactivateSpinner();
        // this.route.navigate(['/wellcome']);
        this.toastr.success("Your registration has been successful! Please check you email ID/SMS for login credentials.")
        setTimeout(() => {
          this.route.navigate(['/tutorHome']);
        }, 500);
      }, err => {
        this.toastr.error(err.error ? err.error.text || err.error : err); this.deactivateSpinner()
      })

  }

  getTenantCode(Company_id: number): number {
    let tenant_code = 0;
    const result = this.companyList.find(e => e.COMPANY_ID == Company_id);
    if (result)
      tenant_code = result.TNT_CODE;
    return tenant_code
  }

  checkSamvaadUserExists(loginEmail: string, payLoad: any, Data: any, isNewUser: boolean = false) {
    this.activeSpinner();
    this.CommonService.getCall(`nojwt/login/getUserDeatilsBy/${loginEmail}`, '', true).subscribe(
      (res: any) => {
        if (isNewUser) {
          if (res.data.length > 0) {
            const { password } = res.data[0];
            this.samvaadUserPWD = password;
            this.SaveSamvaadUser(loginEmail, this.samvaadUserPWD, Data.TNT_CODE);
          }
        }
        else {
          if (res.data.length > 0) {
            const { password } = res.data[0];
            this.samvaadUserPWD = password;
            this.registerAsSamvaadUser(payLoad, Data, true, password);
          }
          else
            this.registerAsSamvaadUser(payLoad, Data);
        }

        this.clear();
        this.deactivateSpinner();
        this.route.navigate(['/login']);
      }, err => {
        this.toastr.error(err.error ? err.error.text || err.error : err); this.deactivateSpinner()
      })
  }

  registerAsSamvaadUser(item: any, data: any, isExistingSamvaadUser = false, pwd: string = '') {

    if (isExistingSamvaadUser == false) {

      let payLoad = {
        ...this.RegUser,
        name: item['TNT_NAME'],
        mobileNO: item['MobileNo'],
        email: item['emailid'],
        password: data.PWD
      }
      this.CommonService.postCall("nojwt/login/saveOrUpdate", payLoad, true).subscribe((res: any) => {
        console.log(res);
        this.checkSamvaadUserExists(item.emailid, null, data, true);

      }, e => { });
    }
    else
      this.SaveSamvaadUser(item.emailid, this.samvaadUserPWD, data.TNT_CODE);


  }

  SaveSamvaadUser(USERNME: string, PASSWORD: string, TNT_CODE: string) {

    let insertPayload = {
      USERNME, PASSWORD, TNT_CODE

    }

    this.CommonService.postCall("Registration/InsertSamvaadUser", insertPayload).subscribe((res: any) => {
      console.log(res);
    }, e => { });
  }
  cancel() {

  }

  clear() {
    this.signUpOnInit();
    this.submitted = false;
  }

  validationInit() {
    let self = this;
    $('.input100').each(function () {
      $(this).on('blur', function () {
        if ($(this).val().trim() != "") {
          $(this).addClass('has-val');
        }
        else {
          $(this).removeClass('has-val');
        }
      })
    })

    var input = $('.validate-input .input100');

    $('.validate-form').on('submit', function () {
      var check = true;

      for (var i = 0; i < input.length; i++) {
        if (validate(input[i]) == false) {
          showValidate(input[i]);
          check = false;
        }
      }
      return check;
    });


    $('.validate-form .input100').each(function () {
      $(this).focus(function () {
        hideValidate(this);
      });
    });

    function validate(input) {
      // return true
      if ($(input).attr('type') == 'email' || $(input).attr('name') == 'email') {
        return true
        if ($(input).val().trim().match(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/) == null) {
          return false;
        }
      }
      else {
        if ($(input).val().trim() == '') {
          return false;
        }
      }
    }

    function showValidate(input) {
      var thisAlert = $(input).parent();
      $(thisAlert).addClass('alert-validate');
    }

    function hideValidate(input) {
      var thisAlert = $(input).parent();
      $(thisAlert).removeClass('alert-validate');
    }

    var showPass = 0;
    $('.btn-show-pass').on('click', function () {
      if (showPass == 0) {
        $(this).next('input').attr('type', 'text');
        $(this).find('i').removeClass('zmdi-eye');
        $(this).find('i').addClass('zmdi-eye-off');
        showPass = 1;
      }
      else {
        $(this).next('input').attr('type', 'password');
        $(this).find('i').addClass('zmdi-eye');
        $(this).find('i').removeClass('zmdi-eye-off');
        showPass = 0;
      }

    });

  }

  numberValidate(event: any) {
    event.target.value = event.target.value.replace(/[^0-9]*/g, '');
  }
  nameValidate(event: any) {
    event.target.value = event.target.value.replace(/[^A-Za-z. ]*/g, '');
  }



  closeModel() {
    this.isLoginDetailsSubmitted = false;
  }

  companyDetails() {
    const { fileUrl } = environment;
    if (sessionStorage.homepageimage_path) {
      document.getElementById("homepageimage_path").setAttribute("src", `${fileUrl}${sessionStorage.homepageimage_path} `);

    }
  }

  getCompanyDetails() {
    const len: number = sessionStorage.length;
    if (len == 0)
      sessionStorage.isDomain = false;
    const { fileUrl } = environment;
    let { hostname } = location;
    if (["localhost", "shiksion.com"].includes(hostname))
      return;
    if (len == 0) {
      this.CommonService.getCall(`account/IsSubDomainExists/${hostname}`).subscribe((res: any) => {
        if (res.data == true) {
          this.CommonService.getCall(`account/GetCompanyDetails/${hostname}`).subscribe((res: any) => {
            if (res.data.length > 0) {
              sessionStorage.isDomain = true;
              sessionStorage.company_id = res.data[0].company_id;
              if (res.data[0].cerficateimage_path)
                sessionStorage.cerficateimage_path = res.data[0].cerficateimage_path;
              if (res.data[0].favicon_path)
                sessionStorage.favicon_path = res.data[0].favicon_path;
              if (res.data[0].homepageimage_path)
                sessionStorage.homepageimage_path = res.data[0].homepageimage_path;
              if (res.data[0].landingpageimage_path)
                sessionStorage.landingpageimage_path = res.data[0].landingpageimage_path;
              if (sessionStorage.favicon_path) {
                document.getElementById("appFavcon").setAttribute("href", `${fileUrl}${res.data[0].favicon_path}`);
              }
              if (sessionStorage.homepageimage_path) {
                document.getElementById("homepageimage_path").setAttribute("src", `${fileUrl}${sessionStorage.homepageimage_path} `);
              }

              // document.getElementById("homepageimage_path")
              console.log("AppComponent");

            }
          });
        }

      });
    }
  }

  getCompanyList() {
    this.CommonService.getCall(`Registration/GetCompanyList`).subscribe((res: any) => {
      this.companyList = res.data;
      console.log(this.companyList);
    })
  }
}
